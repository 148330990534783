<template>
  <div :ref="embed ? 'localWindow' : ''">
    <div v-if="dashboard">
      <v-card flat class="mt-1">
        <v-img
          :src="dashboard.image_url"
          :height="embed ? 100 : 150"
          :ratio="16 / 9"
          class="align-end"
          cover
        >
          <v-card-title v-if="!embed" style="background-color: #0000009d">
            <h1 class="white--text font-weight-light display-1">
              {{ dashboard.name }}
            </h1>
          </v-card-title>

          <v-card-title v-else style="background-color: #0000009d">
            <div>
              <div
                class="white--text font-weight-light text-body-2 text-md-subtitle-1"
              >
                {{ dashboard.name }}
              </div>
              <div class="white--text font-weight-light text-h5 text-md-h4">
                {{ folderName }}
              </div>
            </div>
          </v-card-title>
        </v-img>
      </v-card>

      <v-row v-if="!embed && dashboard && isAdminMode">
        <v-col cols="12" lg="4" md="4" sm="4" xs="12">
          <v-btn
            class="my-3"
            outlined
            block
            color="primary"
            @click.prevent="dialogObjectForm = true"
          >
            Vincular novo objeto Qlik
          </v-btn>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xs="12">
          <v-btn
            v-if="!enableOrdering"
            @click.prevent="enableOrdering = true"
            class="my-3"
            outlined
            block
            color="warning"
          >
            Habilitar ordenação de objetos
          </v-btn>

          <v-btn
            v-else
            @click.prevent="saveOrdering"
            :loading="loadingOrdering"
            class="my-3 text-white"
            block
            color="warning"
          >
            Salvar ordenação
          </v-btn>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xs="12">
          <v-btn
            class="my-3"
            outlined
            block
            color="red"
            @click.prevent="clearCacheConfirmation()"
            :loading="loadingClearCache"
            :disabled="loadingClearCache"
          >
            Recarregar Configurações de Cache
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-if="!embed" v-model="dialogObjectForm" max-width="900px">
        <DashboardAddObject
          :dashboard="dashboard"
          :app="app"
          :folders="dashboardFolders"
          :folder="filters.folder"
          @dataFormSaved="dataSaved"
          @cancelSavingForm="closeDialogObjectForm"
        />
      </v-dialog>

      <div v-if="dashboardFolders && dashboardFolders.length">
        <v-tabs
          v-model="tab"
          show-arrows
          center-active
          flat
          grow
          v-if="
            !embed ||
            ($route.query.folder_visibility &&
              $route.query.folder_visibility == 'show')
          "
        >
          <v-tab
            v-for="(folder, index) in dashboardFolders"
            :key="folder.id"
            background-color="transparent"
            color="primary"
            grow
            @click="filters.folder = folder.alias"
          >
            {{ folder.name }} ({{ index + 1 }}/{{ dashboardFolders.length }})
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" small right>
                  mdi-information-outline
                </v-icon>
              </template>
              <span> {{ folder.description }} </span>
            </v-tooltip>
          </v-tab>
        </v-tabs>

        <v-card outlined class="mt-3 p-0 elevation-0">
          <v-row>
            <v-col
              style="background-color: #f5f5f5; border: 0"
              cols="12"
              :lg="isAdminMode ? 8 : 12"
              :md="isAdminMode ? 6 : 12"
              sm="12"
              xs="12"
            >
              <EmbedQlikCustomSearchBar class="mx-0" :app="app" />
            </v-col>

            <v-col cols="12" lg="4" md="6" sm="12" xs="12" v-if="isAdminMode">
              <v-select
                v-model="dashboard.theme"
                :items="themes"
                label="Tema"
                item-text="text"
                item-value="id"
                outlined
                hide-details
                dense
                @change="updateTheme($event)"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>

        <v-row>
          <v-alert
            v-if="showAlertReload"
            style="margin-bottom: -5px; margin-top: 30px"
            class="mx-auto"
            min-width="300"
            max-width="700"
            v-model="showAlertReload"
            transition="fade-transition"
            dismissible
            color="#fcbf3e"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-information"
            align="center"
          >
            Caso os gráficos demorem a carregar, atualize a página.
          </v-alert>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="folder in dashboardFolders"
            :key="folder.name"
            class="pt-10"
          >
            <draggable
              style="margin: -7px; margin-bottom: 10px"
              v-if="
                filters.folder == folder.alias &&
                folder.relationships.objects.length
              "
              v-bind="dragOptions"
              class="row"
              align="start"
              justify="start"
              :id="'dragable-' + filters.folder"
            >
              <v-col
                style="padding: 7px"
                v-for="(object, index) in folder.relationships.objects"
                :data-id="object.id"
                :key="`${index}-${object.qlik_object_id}`"
                cols="12"
                :sm="object.width"
              >
                <v-card :outlined="object.outlined" :flat="!object.outlined">
                  <v-btn
                    fab
                    color="warning"
                    top
                    left
                    absolute
                    v-show="enableOrdering"
                    class="enableOrdering"
                  >
                    <v-icon>mdi-arrow-all</v-icon>
                  </v-btn>

                  <EmbedQlikObject
                    :app="app"
                    :objectId="object.relationships.object.object"
                    :height="object.height"
                    :imageButton="object.export_image"
                    :pdfButton="object.export_pdf"
                    :csvButton="object.export_csv"
                    :xlsButton="object.export_xls"
                    :maximize="object.maximize"
                    :infoButton="object.show_info"
                    :infoDescription="object.relationships.object.description"
                    :forceUpdate.sync="object.force_update"
                  />

                  <v-divider v-if="isAdminMode"></v-divider>

                  <v-card-actions v-if="isAdminMode && !enableOrdering">
                    <DashboardObjectControl
                      :object="object"
                      :folders="dashboardFolders"
                      @dataRemoved="dataRemoved"
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
            </draggable>

            <v-row v-else align="center" justify="center">
              <p class="my-5">Este dashboard ainda não possui objetos.</p>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-row v-else align="center" justify="center">
        <p class="my-5">Este dashboard ainda não possui pastas.</p>
      </v-row>
    </div>

    <div v-else>
      <v-row align="center" justify="center" class="mt-5">
        <v-col cols="12">
          <v-skeleton-loader
            v-bind="skeleton"
            type="table-heading,list-item-two-line"
          ></v-skeleton-loader>
        </v-col>

        <v-col cols="12">
          <v-skeleton-loader
            v-bind="skeleton"
            type="list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" v-for="(x, idx) in 4" :key="idx">
        <v-col cols="4">
          <v-skeleton-loader v-bind="skeleton" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader v-bind="skeleton" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader v-bind="skeleton" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-overlay v-if="this.loading">
      <v-row align="center" justify="center">
        <v-progress-circular
          class="mb-5"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-row>

      <v-row align="center" justify="center">
        <div class="title">Carregando dados, Aguarde...</div>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import EmbedQlikCustomSearchBar from "../components/QlikComponents/EmbedQlikCustomSearchBar";
import EmbedQlikObject from "../components/QlikComponents/EmbedQlikObject";
import DashboardObjectControl from "../components/dashboards/DashboardObjectControl";
import DashboardAddObject from "../components/dashboards/DashboardAddObject";

import DashboardService from "../services/Dashboard";
import DashboardObjectService from "../services/DashboardObject";

import QdtComponents from "qdt-components";
import Swal from "sweetalert2";
import Draggable from "vuedraggable";

export default {
  name: "DashboardView",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    embed: {
      type: String,
      required: false,
    },
  },
  components: {
    EmbedQlikCustomSearchBar,
    EmbedQlikObject,
    DashboardObjectControl,
    DashboardAddObject,
    Draggable,
  },
  created() {
    if (!this.id) {
      this.$router.push("/");
      return;
    } else {
      this.loadDashboard();
    }

    this.loadDataList();

    if (this.embed) {
      this.timer = setInterval(this.notifyHeight, 250);

      setTimeout(() => clearInterval(this.timer), 10000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  data() {
    return {
      dashboard: null,
      loading: false,
      dialogObjectForm: false,
      app: null,
      tab: 0,
      showAlertReload: true,
      enableOrdering: false,
      loadingClearCache: false,
      loadingOrdering: false,
      loadingThemes: false,
      filters: {
        folder: null,
        folder_visibility: null,
      },
      themes: [],
      skeleton: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      timer: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showAlertReload = false;
    }, "8000");

    if (this.dashboardFolders && this.dashboardFolders.length) {
      this.filters.folder = this.dashboardFolders[0].alias;
      this.tab = 0;
    }
  },
  methods: {
    loadDashboard() {
      this.loading = true;
      DashboardService.get(this.id)
        .then((response) => {
          let dashboard = this.getDataFromModelApi(response.data);
          if (
            (!dashboard.publish && !this.$store.getters["auth/checkAuth"]) ||
            !dashboard.relationships.app
          ) {
            this.$router.push("/");
          } else {
            this.dashboard = dashboard;
          }
        })
        .finally(() => (this.loading = false));
    },
    loadDataList() {
      this.loadingThemes = true;
      DashboardService.dataList()
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            if (this[key] !== undefined) {
              this[key] = response.data[key];
            }
          });
        })
        .finally(() => (this.loadingThemes = false));
    },
    updateTheme(value) {
      this.loadingThemes = true;
      DashboardService.update(this.dashboard.id, { theme: value }).finally(
        () => (this.loadingThemes = false)
      );
    },
    setAppConnection() {
      this.app = new QdtComponents(
        {
          host: this.appHost,
          secure: true,
          port: 443,
          prefix: "",
          appId: this.appId,
        },
        {
          vizApi: true,
          engineApi: true,
        }
      );

      this.getBookmarkListAndApply();
    },
    getBookmarkListAndApply() {
      this.app.qAppPromise
        .then((item) => {
          if (this.dashboard.theme) {
            item.theme
              .get(this.dashboard.theme)
              .then((qTheme) => qTheme.apply());
          }

          item.getList("BookmarkList", function (reply) {
            if (reply.qBookmarkList && reply.qBookmarkList.qItems) {
              reply.qBookmarkList.qItems.forEach((book) =>
                item.bookmark.apply(book.qInfo.qId)
              );
            }
          });
        })
        .catch((err) => console.log("erro qAppPromise", err));
    },
    closeDialogObjectForm() {
      this.dialogObjectForm = false;
    },
    dataSaved() {
      this.clearCache();
      this.closeDialogObjectForm();
    },
    dataRemoved() {
      this.clearCache();
    },
    clearCacheConfirmation() {
      Swal.fire({
        title: "Atenção!",
        text: "Esta operação pode demorar alguns segundos para ser finalizada. Deseja continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.clearCache();
        }
      });
    },
    clearCache() {
      this.loadingClearCache = true;
      DashboardService.dashboardClear({ id: this.dashboard.id, refresh: false })
        .then(() => {
          this.loadDashboard();
          Swal.fire({
            title: "Sucesso!",
            icon: "success",
            text: "Operação de limpeza de cache finalizada.",
          });
        })
        .finally(() => (this.loadingClearCache = false));
    },
    saveOrdering() {
      this.loadingOrdering = true;
      let folderItems = document.getElementById(
        "dragable-" + this.filters.folder
      );
      let objects = [];

      for (let idx in folderItems.children) {
        if (
          folderItems.children[idx] &&
          typeof folderItems.children[idx] == "object"
        ) {
          objects.push({
            id: folderItems.children[idx].getAttribute("data-id"),
            order: idx + 1,
          });
        }
      }

      DashboardObjectService.updateOrders({ objects })
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            icon: "success",
            text: "Nova ordenação salva com sucesso!",
          });
          this.enableOrdering = false;
          this.clearCache();
        })
        .finally(() => (this.loadingOrdering = false));
    },
    notifyHeight() {
      this.$postMessage(
        {
          height: this.$refs.localWindow.scrollHeight + 10,
        },
        "*",
        window.parent
      );
    },
  },
  computed: {
    dashboardFolders() {
      return this.dashboard.relationships.folders;
    },
    folderName() {
      let folder = this.dashboardFolders.find(
        (folder) => folder.alias == this.filters.folder
      );
      if (folder) {
        return folder.name;
      }
      return "";
    },
    hasApp() {
      return !!this.dashboard.relationships.app;
    },
    appId() {
      return this.dashboard.relationships.app.app;
    },
    appHost() {
      return this.dashboard.relationships.app.relationships.host.dns;
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: !this.enableOrdering,
        handle: ".enableOrdering",
        ghostClass: "ghost",
      };
    },
    isAdminMode() {
      return this.$store.getters["auth/adminMode"];
    },
  },
  watch: {
    id() {
      this.loadDashboard();
      this.setFiltersFromRoute();
    },
    dashboard: {
      handler(dashboard) {
        if (dashboard && this.hasApp) {
          if (this.dashboardFolders && this.dashboardFolders.length) {
            if (!this.filters.folder) {
              this.filters.folder = this.dashboardFolders[0].alias;
              this.tab = 0;
            } else {
              let idxFolder = this.dashboardFolders.findIndex(
                (folder) => folder.alias == this.filters.folder
              );
              if (idxFolder > -1) {
                this.tab = idxFolder;
              } else {
                this.filters.folder = this.dashboardFolders[0].alias;
                this.tab = 0;
              }
            }
          }

          this.setAppConnection();
        }
      },
      deep: true,
      immediate: true,
    },
    filters: {
      handler(filters) {
        let filters_replaced = {};
        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            filters_replaced[key] = filters[key];
          }
        });
        this.$router.replace({ query: filters_replaced }).catch(() => {});
      },
      deep: true,
    },
  },
};
</script>


<style>
.theme--light.v-application {
  background-color: #f5f5f5;
}

.theme--light.v-tabs-items {
  background-color: inherit;
}
</style>
