<template>
  <v-navigation-drawer
    class="drawer-menu" v-model="drawerControl" :color="color"
    :src="bg" app temporary dark width="320">
    <v-list>
      <v-subheader class="ml-1 my-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-3" v-on="on" v-bind="attrs" @click="closeDrawer()">mdi-backburger</v-icon>
          </template>
          <span>Close menu</span>
        </v-tooltip>
        Infra S.A. ONTL
      </v-subheader>

      <v-list-group v-if="$store.getters['auth/checkAuth'] && user" v-model="active" active-class="amber--text">
        <template v-slot:activator>
    <v-list-item two-line>
      <v-list-item-avatar horizontal>
        <v-img src="../assets/avatar.png"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ user.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

  </template>
  <template>
  <v-list-item link v-if="!user.email.endsWith('@infrasa.gov.br')"  @click="showChangePasswordDialog = true">
          <v-list-item-icon>
            <v-icon>mdi-lock-open</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Trocar Senha </v-list-item-title>
        </v-list-item>
  <v-dialog v-model="showChangePasswordDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        Troca de Senha
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
        :type="showCurrentPassword ? 'text' : 'password'"
        label="Senha atual"
        v-model="currentPassword"
        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowCurrentPassword"
      ></v-text-field>
      
      <v-text-field
        :type="showNewPassword ? 'text' : 'password'"
        label="Nova senha"
        v-model="newPassword"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowNewPassword"
      ></v-text-field>
      
      <v-text-field
        :type="showConfirmNewPassword ? 'text' : 'password'"
        label="Confirmar nova senha"
        v-model="confirmNewPassword"
        :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowConfirmNewPassword"
      ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="showChangePasswordDialog = false">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="submitChangePassword()">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
        <v-subheader v-if="user.email.endsWith('@infrasa.gov.br')" class="ml-3 my-3">ÁREA RESTRITA</v-subheader>
        
        <v-list-item v-if="user.email.endsWith('@infrasa.gov.br')" class="pt-2">
          <v-switch
            v-model="adminMode"
            :label="adminMode ? 'Modo administrador' : 'Modo comum'"></v-switch>
        </v-list-item>

        <span v-if="user.is_admin">
          <v-list-item link v-for="ro in restrictOptions" :key="ro.title">
            <v-list-item-icon>
              <v-icon>{{ ro.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="$router.push(ro.path).catch((_) => {});
              $emit('update:drawer', false);
              active = false;">
              <v-list-item-title>{{ ro.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>

        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Sair </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item v-else
        link two-line @click.prevent="$emit('update:showLogin', true)">
        <v-list-item-avatar>
          <v-img src="../assets/avatar.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Visitante</v-list-item-title>
          <v-list-item-subtitle>Clique para fazer login</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader class="ml-3 my-3">DASHBOARDS</v-subheader>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-content
          @click="$emit('update:drawer', false); $router.push({ name: 'home' }).catch((_) => {});">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-for="dashboard in dashboards" :key="dashboard.id" link>
        <v-list-item-icon>
          <v-icon>{{ dashboard.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content
  @click="navigate(dashboard)">
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"> 
        <v-list-item-title>
          {{ dashboard.name }}
        </v-list-item-title>
      </span>
    </template>
    <span>{{ dashboard.name }}</span> <br />
    <span>{{ dashboard.description }}</span>
  </v-tooltip>
</v-list-item-content>
      </v-list-item>
      
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  name: 'NavigationDrawer',
  props: {
    drawer: {
      type: Boolean, required: true
    },
    showLogin: {
      type: Boolean, default: false
    },
    dashboards: {
      type: Array, default: () => []
    },
    authorizedGroups: {
      type: Array, default: () => []
    }
  },
  data() {
    return {
      color: 'primary',
      background: false,
      active: false,
      overlay: false,
      showChangePasswordDialog: false,
      currentPassword:'',
      newPassword: '',
      confirmNewPassword:'',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      restrictOptions: [
        { title: 'Admistração Qlik', icon: 'mdi-monitor-dashboard', path: '/qlik' },
        { title: 'Usuários', icon: 'mdi-account-group', path: '/users' },
        { title: 'Imagens', icon: 'mdi-image', path: '/image-manager' }
      ]
    };
  },
  methods: {
    closeDrawer() {
      this.$emit('update:drawer', false);
    },
    toggleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowConfirmNewPassword() {
      this.showConfirmNewPassword = !this.showConfirmNewPassword;
    },
    async submitChangePassword() {
    if (this.newPassword !== this.confirmNewPassword) {
      this.showChangePasswordDialog = true;
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'A nova senha e a confirmação da senha não coincidem.',
            showConfirmButton: false,
            position: 'top-end',
            timer: 1800
        });
        return;
    }

    if (this.newPassword.length <= 6) {
      this.showChangePasswordDialog = true;
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'A senha deve ter mais de 6 caracteres.',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1800
        });
        return;
    }

    try {
        const userId = this.user.id;
        await this.$user.changePassword(userId, this.currentPassword, this.newPassword, this.confirmNewPassword);
        // Limpa os campos e fecha o diálogo
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
        this.showChangePasswordDialog = false;

        // Mostra o alerta de sucesso
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Senha alterada com sucesso!',
            showConfirmButton: false,
            timer: 1500
        });
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Erro ao alterar a senha',
            text: error.message,
        });
    }
}
,
    logout() {
      this.$store.dispatch('auth/logoutUserAuthenticated');
      this.$emit('update:drawer', false);
      this.active = false;

      window.location.href = '/';
    },
    navigate(dashboard) {
      this.$emit('update:drawer', false);

      if (dashboard.id === 35) {
        this.$router.push('/seguranca-viaria').catch(() => {});
      } else {
        this.$router.push('/dashboard/' + dashboard.id).catch(() => {});
      }
    }
  },
  computed: {
    drawerControl: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit('update:drawer', value);
      }
    },
    adminMode: {
      get() {
        return this.$store.getters['auth/adminMode'];
      },
      set(value) {
        this.$store.dispatch('auth/toggleAdminMode');
        if (!value) {
          this.$emit('update:drawer', false);
        }
      },
    },
    bg() {
      return this.background
        ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg'
        : undefined;
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  }
};
</script>

<style scoped>
.drawer-menu {
  z-index: 2;
}
</style>
