<template>
  <QlikInterface
    ref="qlikRef"
    :qAppPromise.sync="qAppPromise"
    :qDocPromise.sync="qDocPromise"
    :qdtViz.sync="qdtViz"
    :type="type"
    :props="config"
    :app="app" />
</template>

<script>
import QlikInterface from './QlikInterface';

export default {
  name: 'EmbedQlikCustomSearchBar',
  components: {
    QlikInterface
  },
  props: {
    height: {
      type: Number
    },
    app: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    qAppPromise: null,
    qDocPromise: null,
    qdtViz: null,
    type: 'QdtCurrentSelections',
    otherType: 'QdtCurrentSelections',
    config: {
      type: 'QdtCurrentSelections',
      title: 'Minhas Seleções',
      btnText: 'Limpar seleções'
    }
  })
};
</script>

<style>
  .qv-subtoolbar-button {
    display: none !important;
  }
</style>