import Service from './Service';

class ImageService extends Service {
    constructor() {
        super('images');
    }

    uploadImages(files) {
        const formData = new FormData();
        files.forEach(file => formData.append('images[]', file));

        return this.axios.post('/upload-images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    deleteImage(id) {
        return this.axios.delete(`/delete-image/${id}`);
    }
}

export default new ImageService();
