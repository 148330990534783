export default {
    folder_id: null,
    qlik_object_id: null,
    order: null,
    height: 400,
    width: 12,
    x: 0,
    y: 0,
    export_pdf: null,
    export_xls: null,
    export_csv: null,
    export_json: null,
    export_image: null,
    show_info: null,
    outlined: null,
    maximize: null
}
