<template>
  <v-data-table
    dense
    :headers="headers"
    :items="getItemsFromCollection"
    :options.sync="options"
    :server-items-length="collection.meta.total || 0"
    class="elevation-1"
    :footer-props="configDataTable"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Grupos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-text-field @change="filters.search = $event" :value="filters.search"
          append-icon="mdi-magnify" label="Pesquisar" hide-details></v-text-field>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialogForm" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="addData('dialogGroupsForm')">
              Adicionar
            </v-btn>
          </template>

          <GroupsForm
            ref="dialogGroupsForm"
            @dataFormSaved="dataSaved"
            @cancelSavingForm="closeDialogForm" />
        </v-dialog>
      </v-toolbar>

      <v-progress-linear indeterminate color="primary" v-show="loading"></v-progress-linear>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="editData(item, 'dialogGroupsForm')">
            <v-icon small class="mr-2" color="dark">
              mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="removeData(item)">
            <v-icon small class="mr-2" color="red">
              mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>

    <template v-slot:no-data>
      <p class="red--text">Não há registros a serem exibidos!</p>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} de {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
  import GroupsForm from './GroupsForm';
  import GroupService from '../../services/Group';
  import CrudDataTableMixin from '../../mixins/CrudDataTableMixin';

  export default {
    name: 'groups',
    mixins: [CrudDataTableMixin],
    components: {
      GroupsForm
    },
    data() {
      return {
        service: GroupService,
        dialogForm: false,
        nameCollection: 'collection',
        headers: [
          { text: '#', value: 'index', width: 50, sortable: false },
          { text: 'Grupo', value: 'name' },
          { text: 'descrição', value: 'description', sortable: false },
          { text: 'Ações', value: 'actions', sortable: false, width: 150 }
        ],
        filters: {
          search: '',
          page: '',
          sortBy: '',
          sortOrder: '',
          per_page: ''
        }
      }
    }
  }
</script>