import Swal from 'sweetalert2';

export default {
	data() {
		return {
      loading: false,
      configDataTable: {
        'items-per-page-options': [15, 30, 40, 50, 100],
        'items-per-page-text': 'Registros por página'
      },
      options: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 15
      },
      collection: {
        data: [],
        links: { self: '' },
        meta: {}
      }
		}
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.service.getAll(this.getUrlString)
        .then(response => this.collection = response.data)
        .finally(() => this.loading = false);
    },
    closeDialogForm() {
      this.dialogForm = false;
    },
    dataSaved() {
      this.getDataFromApi();
      this.closeDialogForm();
    },
    addData(ref) {
      this.dialogForm = true;  
      this.$nextTick(() => {
        this.$refs[ref].setResourceId(null);
      });
    },
    editData(item, ref) {
      this.dialogForm = true;
      this.$nextTick(() => {
        this.$refs[ref].setResourceId(item.id);
        this.$refs[ref].getDataFromApi();
      });
    },
    removeData(item) {
      Swal.fire({
        title: 'Está certo de excluir esse registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.service.remove(item.id).then(() => this.getDataFromApi());
        }
      });
    },
    updateFields(item, fields) {
      this.loading = true;
      this.service.update(item.id, { ...fields })
        .then(() => this.getDataFromApi())
        .finally(() => this.loading = false);
    }
  },
  computed: {
    getUrlString() {
      return `?${this.getQueryStringFromRoute}`;
    }
  },
  watch: {
    options: {
      handler() {
        let paginationAttrs = {
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'asc' : 'desc',
          page: this.options.page,
          per_page: this.options.itemsPerPage
        };

        Object.keys(this.filters).forEach(key => {
          if (paginationAttrs[key]) {
            this.filters[key] = paginationAttrs[key];
          }
        });
      },
      deep: true
    },
    dialogForm(val) {
      if (!val) {
        this.closeDialogForm();
      }
    },
    filters: {
      handler(filters) {
        let filters_replaced = {};
        Object.keys(filters).forEach(key => {
          if (filters[key]) {
            filters_replaced[key] = filters[key];
          }
        });
        this.$router.replace({ query: filters_replaced}).catch(()=>{});
        this.getDataFromApi();
      },
      deep: true
    }
  }
}