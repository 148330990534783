import axios from 'axios';

const Service = class Service {
    constructor(base) {
        this.base = base;
        this.axios = axios;
    }

    getHeaders() {
        // Retorne os cabeçalhos que você precisa aqui. Exemplo:
        return {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + seuToken, // Se você estiver usando autenticação
        };
    }

    getAll(params = '') {
        return this.axios.get(`${this.base}${params}`);
    }

    get(id) {
        return this.axios.get(`${this.base}/${id}`);
    }

    save(data) {
        return this.axios.post(`${this.base}`, data);
    }

    update(id, data) {
        return this.axios.put(`${this.base}/${id}`, data);
    }

    remove(id) {
        return this.axios.delete(`${this.base}/${id}`);
    }
}

export default Service;