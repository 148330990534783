<template>
  <v-form v-model="formValid" ref="formValid" @submit.prevent="beforeSave">
    <v-card>
      <v-card-title v-if="formTitle">
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Nome do grupo" v-model="resource.name"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea label="Descrição do grupo" v-model="resource.description"
                hide-details rows="3"></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-combobox v-model="users_selected" :items="users" label="Adicionar usuários"
                item-text="name" item-value="id" :loading="loading.users"
                hide-details chips small-chips multiple></v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text class="mb-2" @click="cancelSaving" :disabled="loading.save">
          Cancelar
        </v-btn>
        <v-btn color="primary" text class="mb-2" type="submit" :loading="loading.save" :disabled="loading.save || !formValid">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import GroupModel from '../../models/group';
  import GroupService from '../../services/Group';
  import UserService from '../../services/User';
  import CrudDataFormMixin from '../../mixins/CrudDataFormMixin';

  export default {
    name: 'grupos-form',
    mixins: [CrudDataFormMixin],
    created() {
      this.getUsersDataFromApi();
    },
    data() {
      return {
        labels: {
          formTitleAdd: 'Adicionar grupo',
          formTitleEdit: 'Editar grupo'
        },
        model: { ...GroupModel },
        resource: { ...GroupModel },
        service: GroupService,
        users: [],
        users_selected: [],
        loading: {
          save: false,
          users: false
        }
      }
    },
    methods: {
      beforeSave() {
        this.resource.users = this.users_selected.map(user => { return user.id });
        this.save();
      },
      getUsersDataFromApi() {
        this.loading.users = true;
        UserService.getAll()
          .then(response => this.users = response.data.data.map((r, idx) => this.getDataFromModelApi(r, idx)))
          .finally(() => this.loading.users = false);
      }
    },
    watch: {
      'resource.users': function(users) {
        if (users && users.data && users.data.length) {
          this.users_selected = users.data.map((resource, index) => this.getDataFromModelApi(resource, (index+1)));
        }
      }
    }
  }
</script>
