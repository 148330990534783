import Swal from 'sweetalert2';
import axios from 'axios';
import localstorage from './localstorage';
import ConstsConfig from './config';

const lsToken = localstorage.getObject(ConstsConfig.tokenName);

export default function setup() {
    axios.interceptors.request.use(config => {
        if (config.url.indexOf('http') == -1) {
            config.baseURL = ConstsConfig.apiUrl;
        }

        if (lsToken) {
            config.headers.Authorization = `Bearer ${lsToken}`;
        }

        return config;
    }, function (err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(
        response => response,
        error => {
            let title = 'Atenção!';
            let errorMessage = '';
            
            if (!error || !error.response) {
                errorMessage = 'Houve um erro desconhecido. Tente novamente mais tarde!';
            } else {
                if (error.response.status == 422) {
                    title = 'Campos inválidos.';
                    errorMessage += '<p style="color: red">';
                    for (let i in error.response.data.errors.meta) {
                        for (let j in error.response.data.errors.meta[i]) {
                            errorMessage += error.response.data.errors.meta[i][j] += '<br />';
                        }
                    }
                    errorMessage += '</p>';
                } else if (error.response.status == 401) {
                    errorMessage = 'Usuário não autenticado.';
                } else {
                    if (error.response && error.response.data) {
                        errorMessage = error.response.data.message;
                    } else {
                        errorMessage = error.message;
                    }
                }
            }

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: title,
                html: errorMessage,
                showConfirmButton: true
            });

            return Promise.reject(error.message);
        }
    );
}
