import axios from "axios";
const API_URL = process.env.VUE_APP_ROOT_API_BACKEND;

const state = {
  loadingDashboards: false,
  dashboards: null,
  dashboard: null,
  dashboardsHeaders: [
    { text: "ID", value: "id", },
    { text: "Dashboard", align: "start", value: "name", },
    { text: "Grupo", align: "start", value: "group", },
    { text: "Icone do menu", value: "icon", },
    { text: "Imagem do cabeçalho", value: "image_url", },
    { text: "Ações", value: "actions" },
  ],

  allQdts: [],

  loadingDashboard: false,
  activeTab: null,
  showAlertReload: true,

  loadingClearingCache: false
};

const getters = {
  dashboards: (state) => state.dashboards,
  allQdts: (state) => state.allQdts,
  loadingDashboards: (state) => state.loadingDashboards,
  dashboard: (state) => state.dashboard,
  dashboardsHeaders: (state) => state.dashboardsHeaders,
  dashboardFolders: (state) => {
    return state.dashboard.links.folders;
  },

  loadingDashboard: (state) => state.loadingDashboard,
  showAlertReload: (state) => state.showAlertReload,
  activeTab: (state) => state.activeTab,

  loadingClearingCache: (state) => state.loadingClearingCache
};

const actions = {
  /* Dashboars action section */
  createDashboard({ commit }, data) {
    return axios
      .post(`${API_URL}/dashboards`, data)
      .then((res) => {
        commit("addDashboard", res.data);
        return res;
      });
  },
  updateDashboard({ commit }, item) {
    item._method = "patch";
    return axios
      .patch(`${API_URL}/dashboards/${item.id}`, item)
      .then((res) => {
        commit("updateDashboard", res.data);
        return res;
      });
  },
  fetchDashboards({ commit }, queryString = '') {
    commit("setLoadingDashboards", true);
    axios
      .get(`${API_URL}/dashboards${queryString}`)
      .then((res) => commit("setDashboards", res.data))
      .catch((err) => console.log(err))
      .finally(commit("setLoadingDashboards", false));
  },
  fetchQdts({ commit }) {
    axios
      .get(`${API_URL}/qlik-apps`)
      .then(res => commit("setQdts", res.data || []))
      .catch((err) => console.log(err));
  },
  async fetchDashboard({ commit, dispatch }, itemId) {
    try {
      commit("setLoadingDashboard", true);
      commit("setDashboard", null);
      const res = await axios.get(`${API_URL}/dashboards/${itemId}`);
      commit("setDashboard", res.data);
      dispatch("countDownReloadAlert");

      if (res.data.links.folders.data && res.data.links.folders.data.length) {
        const tab = res.data.links.folders.data[0].data.attributes.name;
        commit("setActiveTab", tab);
      }

      commit("setLoadingDashboard", false);
    } catch (e) {
      console.log(e);
    }
  },
  async dashboardClear({ dispatch }, data) {
    try {
      let res = null;
      if (data && data.id) {
        const dashboard_id = data.id;
  
        if (Array.isArray(dashboard_id)) {
          res = await axios.post(`${API_URL}/dashboards/clear`, { dashboards_id: dashboard_id });
        } else {
          res = await axios.get(`${API_URL}/dashboards/clear/${dashboard_id}`);
        }
  
        if (data.refresh) {
          dispatch("fetchDashboard", dashboard_id);
        }
      }

      return res;
    } catch (e) {
      console.log(e);
    }
  },

  countDownReloadAlert({ commit }) {
    setTimeout(() => {
      commit("setShowAlertReload", false);
    }, 8000);
  },
  selectDashboard({ commit }, item) {
    commit("selectDashboard", item);
  },
  deleteDashboard: ({ commit }, item) => {
    axios
      .delete(`${API_URL}/dashboards/${item.id}`)
      .then(res => {
        commit("removeDashboard", item);
        return res;
      });
  },
  /* DashboardObject action section */
  addDashboardObject({ commit }, data) {
    //commit("addDashboardObject", data);
    axios
      .post(`${API_URL}/dashboard-objects`, data)
      .then((res) => commit("addDashboardObject", res.data))
      .catch((err) => console.log(err));
  },
  updateDashboardObject({ commit }, data) {
    data._method = "patch";
    axios
      .patch(`${API_URL}/dashboard-objects/${data.dashboard_id}`, data)
      .then((res) => commit("updateDashboardObject", res.data))
      .catch((err) => console.log(err));
  },
  deleteDashboardObject: ({ commit }, item) => {
    axios
      .delete(`${API_URL}/dashboard-objects/${item.data.id}`)
      .then(() => commit("removeDashboardObject", item))
      .catch((err) => console.log(err));
  },
  removeChild: ({ getters, commit }, item) => {
    const index = getters.dashboard.children.indexOf(item);
    commit("removeChild", index);
  },

  /* DashboardFolders action section */
  createFolder({ commit }, data) {
    axios
      .post(`${API_URL}/folders`, data)
      .then((res) => commit("addFolder", res.data))
      .catch((err) => console.log(err));
  },
  deleteFolder: ({ commit }, item) => {
    axios
      .delete(`${API_URL}/folders/${item.id}`)
      .then(() => commit("removeFolder", item))
      .catch((err) => console.log(err));
  },
  updateFolder({ commit }, item) {
    item._method = "patch";
    axios
      .patch(`${API_URL}/folders/${item.id}`, item)
      .then((res) => commit("updateFolder", res.data))
      .catch((err) => console.log(err));
  },

  startLoadingClearingCache({ commit }) {
    commit("setLoadingClearingCache", true);
  },
  stopLoadingClearingCache({ commit }) {
    commit("setLoadingClearingCache", false);
  }
};

const mutations = {
  /* Dashboards mutation section */
  setShowAlertReload(state, value) {
    state.showAlertReload = value;
  },
  setActiveTab(state, value) {
    state.activeTab = value;
  },
  setLoadingDashboards(state, value) {
    state.loadingDashboards = value;
  },
  setLoadingDashboard(state, value) {
    state.loadingDashboard = value;
  },
  setDashboards(state, value) {
    state.dashboards = value;
  },
  setQdts(state, value) {
    state.allQdts = value;
  },
  setDashboard(state, value) {
    state.dashboard = value;
  },
  selectDashboard(state, value) {
    state.dashboard = state.dashboards.data.find(
      (item) => item.data.id == value.id
    );
  },
  removeDashboard(state, item) {
    var dashboardIds = state.dashboards.data.map((dash) => dash.data.id);
    var index = dashboardIds.indexOf(item.id);
    state.dashboards.data.splice(index, 1);
  },
  updateDashboard(state, item) {
    var dashboardIds = state.dashboards.data.map((dash) => dash.data.id);
    var editedIndex = dashboardIds.indexOf(item.data.id);
    Object.assign(state.dashboards.data[editedIndex], item);
  },

  /* DashboardObjects mutation section */
  addDashboard(state, value) {
    state.dashboards.data.push(value);
  },
  addDashboardObject(state, value) {
    var folder_id = value.data.attributes.folder_id;
    var folder = state.dashboard.links.folders.data.find(
      (f) => f.data.id == folder_id
    );
    folder.links.children.data.push(value);
  },
  removeDashboardObject(state, item) {
    var folder_id = item.data.attributes.folder_id;
    var folder = state.dashboard.links.folders.data.find(
      (f) => f.data.id == folder_id
    );

    var dashboardObjectsIds = folder.links.children.data.map(
      (child) => child.data.id
    );
    var index = dashboardObjectsIds.indexOf(item.data.id);
    folder.links.children.data.splice(index, 1);
  },
  updateDashboardObject(state, item) {
    var folder_id = item.data.attributes.folder_id;
    var folder = state.dashboard.links.folders.data.find(
      (f) => f.data.id == folder_id
    );
    var dashboardObjectsIds = folder.links.children.data.map(
      (child) => child.data.id
    );
    var editedIndex = dashboardObjectsIds.indexOf(item.data.id);
    //Hack to force qlikInterface to re-render it self after any object modification
    item.data.attributes.force_update = true;
    if (editedIndex >= 0)
      Object.assign(folder.links.children.data[editedIndex], item);
    else {
      folder.links.children.data.push(item);
    }
  },

  /* DashboardFolders mutation section */
  addFolder(state, newFolder) {
    state.dashboard.links.folders.data.push(newFolder);
  },
  removeFolder(state, item) {
    var folders = state.dashboard.links.folders;
    var ids = folders.data.map((i) => i.data.id);
    var index = ids.indexOf(item.id);
    folders.data.splice(index, 1);
  },
  updateFolder(state, item) {
    var folders = state.dashboard.links.folders;
    var ids = folders.data.map((i) => i.data.id);
    var editedIndex = ids.indexOf(item.data.id);
    Object.assign(folders.data[editedIndex], item);
  },
  setLoadingDashboardFolders(state, value) {
    state.loadingDashboardFolders = value;
  },

  setLoadingClearingCache(state, value) {
    state.loadingClearingCache = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
