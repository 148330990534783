import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import Clipboard from 'v-clipboard';
import VuePostmessage from 'vue-postmessage';

import HelperMixin from './mixins/HelperMixin';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import interceptors from './interceptors';

import user from './services/User';

Vue.use(Clipboard);
Vue.use(VuePostmessage);

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
});

Vue.mixin(HelperMixin);

Vue.prototype.$user = user;

Vue.config.productionTip = false;

interceptors();

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
