<template>
  <v-form v-model="formValid" ref="formValid" @submit.prevent="beforeSave">
    <v-card>
      <v-card-title v-if="formTitle">
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Nome" v-model="resource.name" hide-details></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-textarea label="Descrição" v-model="resource.description" hide-details rows="3"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text class="mb-2" @click="cancelSaving" :disabled="loading.save">
          Cancelar
        </v-btn>
        <v-btn color="primary" text class="mb-2" type="submit" :loading="loading.save" :disabled="loading.save || !formValid">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import FolderModel from '../../models/folder';
  import FolderService from '../../services/Folder';
  import CrudDataFormMixin from '../../mixins/CrudDataFormMixin';

  export default {
    name: 'folders-form',
    mixins: [CrudDataFormMixin],
    props: {
      dashboard: {
        type: Object, default: () => null
      }
    },
    data() {
      return {
        labels: {
          formTitleAdd: 'Adicionar pasta',
          formTitleEdit: 'Editar pasta'
        },
        model: { ...FolderModel },
        resource: { ...FolderModel },
        service: FolderService,
        loading: {
          save: false
        }
      }
    },
    methods: {
      beforeSave() {
        if (this.dashboard && this.dashboard.id) {
          this.resource.dashboard_id = this.dashboard.id;
        }

        this.save();
      }
    }
  }
</script>
