import localstorage from '../../localstorage';
import config from '../../config';

const lsToken = localstorage.getObject(config.tokenName);

function getFromModel(resource) {
	if (!resource) {
		return null;
	}

	return {
        id: resource.data.id,
        ...resource.data.attributes
	};
}

export default {
	namespaced: true,
	state: {
		user: localstorage.getObject(config.storageUserKey),
		checkAuth: !!lsToken,
		adminMode: false
	},

	mutations: {
		SETUSERAUTHENTICATED: (state, user) => state.user = user,
		SETCHECKAUTH: (state, checkAuth) => state.checkAuth = checkAuth,
		SETADMINMODE: (state, value) => state.adminMode = value
	},

	actions: {
		async setUserAuthenticated(context, data) {
			return new Promise((resolve, reject) => {
				try {
					let user = getFromModel(data);
					localstorage.setObject(config.tokenName, user.access_token);
					context.commit('SETCHECKAUTH', true);

					localstorage.setObject(config.storageUserKey, user);
					context.commit('SETUSERAUTHENTICATED', user);

					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},

		async logoutUserAuthenticated(context) {
			return new Promise((resolve, reject) => {
				try {
					localstorage.remove(config.tokenName);
					localstorage.remove(config.storageUserKey);
					context.commit('SETUSERAUTHENTICATED', null);
					context.commit('SETCHECKAUTH', false);

					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},

		toggleAdminMode(context) {
			context.commit('SETADMINMODE', !context.getters.adminMode);
		}
	},

	getters: {
		user: (state) => state.user,
		checkAuth: (state) => state.checkAuth,
		isAdmin: (state) => state.user.is_admin || state.user.username == 'tacio.brito',
		adminMode: (state, getters) => state.adminMode && getters.isAdmin,
		authorizedGroups: (state) => state.user ? state.user.groups : []
	}
}