<template>
  <div>
    <h2 class="my-5 font-weight-light display-1">Gerenciamento de Imagens</h2>
    <div class="upload-section my-4">
      <h3>Fazer Upload de Nova Imagem</h3>
      <v-form @submit.prevent="uploadImages">
        <v-file-input
          label="Selecione imagens"
          v-model="selectedFiles"
          multiple
          :rules="[fileSelectionRule]"
          required
          ref="fileInput"
        ></v-file-input>
        <v-btn type="submit" color="primary">Upload</v-btn>
        <p class="text-subtitle">*Selecione no máximo 20 imagens</p>
      </v-form>
    </div>

    <v-data-table
      :headers="headers"
      :items="images"
      item-key="id"
      class="elevation-1"
      :search="search"
      sort-by="name"
      :custom-filter="filterOnlyLowerText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Pesquisa"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.thumbnail="{ item }">
        <v-img :src="item.url" class="thumbnail"></v-img>
      </template>

      <template v-slot:item.link="{ item }">
        <a :href="item.url" target="_blank">Abrir Imagem</a>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red lighten-1"
              dark
              v-bind="attrs"
              v-on="on"
              @click="deleteImage(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir Imagem</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ImageService from '../services/ImageService';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      images: [],
      selectedFiles: [],
      search: ''
    };
  },
  mounted() {
    this.loadImages();
  },
  methods: {
    async loadImages() {
      try {
        const response = await ImageService.getAll();
        this.images = response.data.images;
      } catch (error) {
        console.error('Erro ao carregar as imagens:', error);
      }
    },
    async uploadImages() {
      if (!this.selectedFiles.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Por favor, selecione ao menos um arquivo para upload.'
        });
        return;
      }

      if (this.selectedFiles.length > 20) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Você só pode selecionar no máximo 20 arquivos.'
        });
        return;
      }

      try {
        await ImageService.uploadImages(this.selectedFiles);
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Imagens carregadas com sucesso!'
        });
        this.selectedFiles = []; // Limpa o seletor de arquivos
        this.$refs.fileInput.$el.querySelector('input').value = ''; // Reseta o componente de input de arquivos
        this.loadImages(); // Atualiza a lista de imagens
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao fazer upload das imagens.'
        });
        console.error('Erro ao fazer upload das imagens:', error);
      }
    },
    async deleteImage(id) {
      const result = await Swal.fire({
        title: 'Tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, exclua!'
      });

      if (result.isConfirmed) {
        try {
          await ImageService.deleteImage(id);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Imagem excluída com sucesso!'
          });
          this.loadImages(); // Atualiza a lista de imagens
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao excluir a imagem.'
          });
          console.error('Erro ao excluir a imagem:', error);
        }
      }
    },
    filterOnlyLowerText(value, search /*, item */) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    fileSelectionRule(files) {
      if (files.length > 20) {
        return "Você só pode selecionar no máximo 20 arquivos.";
      }
      return true;
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Miniatura', value: 'thumbnail', sortable: false },
        { text: 'Nome da Imagem', value: 'name' },
        { text: 'Formato', value: 'format' },
        { text: 'Link', value: 'link', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false }
      ];
    }
  }
};
</script>

<style>
.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.upload-section {
  margin-bottom: 20px;
}

.text-subtitle {
  font-size: 10px;
  margin: 1px;
}
</style>
