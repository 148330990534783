export default {
  created() {
    this.setFiltersFromRoute();
  },
	methods: {
    createSlug(text, separator = '-') {
      let er = /\W/gi;
      let _this = this;
      text = text.replace(er, (match) => {
        let base = _this.$getConst('CHARMAP')[match];
        return !base || er.test(base) ? separator : base;
      });
      return text.toLowerCase();
    },
    routerLinkTargetBlank(route) {
      let link = this.$router.resolve(route);
      window.open(link.href, '_blank');
    },
    getDataFromModelApi(resource, index = 0) {
      if (!resource) {
        return null;
      }

      let relationships = {};

      if (resource.data.relationships) {
        Object.keys(resource.data.relationships).forEach(key => {
          if (resource.data.relationships[key]) {
            relationships[key] = Array.isArray(resource.data.relationships[key].data)
            ? resource.data.relationships[key].data.map((resource2, index2) => this.getDataFromModelApi(resource2, (index2+1)))
            : this.getDataFromModelApi(resource.data.relationships[key]);
          }
        });
      }

      return {
        id: resource.data.id,
        index: index,
        ...resource.data.attributes,
        relationships: {...relationships}
      };
    },
    setFiltersFromRoute() {
      if (this.$route) {
        let queryFromRoute = this.$route.query;
        Object.keys(queryFromRoute)
          .filter(key => queryFromRoute[key])
          .forEach(key => {
            if (this.filters && this.filters[key] !== undefined) {
              if (!isNaN(queryFromRoute[key])) {
                this.filters[key] = parseInt(queryFromRoute[key]);
              } else {
                this.filters[key] = queryFromRoute[key];
              }
            }
          });
      }
    },
    filterDataAutocomplete(item, queryText, itemText) {
      const text = itemText.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    }
  },
  computed: {
    getQueryStringFromRoute() {
      let queryFromRoute = this.$route.query;
      let queryStringFromRoute = Object.keys(queryFromRoute)
          .filter(key => queryFromRoute[key])
          .map(key => `&${key}=${queryFromRoute[key]}`).join('');
  
      return queryStringFromRoute;
    },
    getItemsFromCollection() {
      if (this.nameCollection) {
        return this[this.nameCollection].data.map((resource, index) => this.getDataFromModelApi(resource, (index+1)));
      }

      return [];
    }
  }
}