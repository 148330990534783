<template>
  <v-form v-model="formValid" ref="formValid" @submit.prevent="save">
    <v-card>
      <v-card-title v-if="formTitle">
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select v-model="resource.host_id" :items="hosts" label="Host Qlik"
                item-text="name" item-value="id" outlined></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field label="ID do app" v-model="resource.app"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Nome" v-model="resource.name"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea label="Descrição" v-model="resource.description"
                hide-details rows="3"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text class="mb-2" @click="cancelSaving" :disabled="loading.save">
          Cancelar
        </v-btn>
        <v-btn color="primary" text class="mb-2" type="submit" :loading="loading.save" :disabled="loading.save || !formValid">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import AppModel from '../../models/qlikApp';
  import AppService from '../../services/QlikApp';
  import CrudDataFormMixin from '../../mixins/CrudDataFormMixin';

  export default {
    name: 'apps-form',
    mixins: [CrudDataFormMixin],
    props: {
      hosts: { type: Array, default: () => [] }
    },
    data() {
      return {
        labels: {
          formTitleAdd: 'Adicionar app',
          formTitleEdit: 'Editar app'
        },
        model: { ...AppModel },
        resource: { ...AppModel },
        service: AppService,
        loading: {
          save: false
        }
      }
    }
  }
</script>
