<template>
  <v-data-table
    dense
    :headers="headers"
    :items="getItemsFromCollection"
    :options.sync="options"
    :server-items-length="collection.meta.total || 0"
    class="elevation-1"
    :footer-props="configDataTable"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Auditoria</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-select v-model="filters.event" :items="eventList" :loading="loadingDataList" :clearable="true"
          label="Filtrar por ação" hide-details no-data-text="Não há dados disponíveis"
          ></v-select>
        <v-spacer></v-spacer>

        <v-select v-model="filters.auditable_type" :items="auditable_types" :loading="loadingDataList"
          item-text="label" item-value="model" :clearable="true"
          label="Filtrar por tipo de dado" hide-details no-data-text="Não há dados disponíveis"
          ></v-select>
        <v-spacer></v-spacer>

        <v-text-field @change="filters.search = $event" :value="filters.search"
          append-icon="mdi-magnify" label="Pesquisar" hide-details></v-text-field>
      </v-toolbar>

      <v-dialog v-model="dialogAuditDetails" max-width="900px">
        <AuditDetails
          ref="auditDetails"
          :events="events"
          :auditable_types="auditable_types"
          @close="closeExpandDetails" />
      </v-dialog>

      <v-progress-linear indeterminate color="primary" v-show="loading"></v-progress-linear>
    </template>

    <template v-slot:item.event="{ item }">
      <span>{{ events[item.event] }}</span>
    </template>

    <template v-slot:item.auditable_type="{ item }">
      <span>{{ auditableType(item.auditable_type).label }}</span>
    </template>

    <template v-slot:item.user="{ item }">
      <span>#{{ item.user_id }} {{ item.relationships.user.name }}</span>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"
            color="dark" class="mr-2"
            @click="expandDetails(item)">mdi-magnify-expand</v-icon>
        </template>
        <span>Expandir detalhes</span>
      </v-tooltip>
    </template>

    <template v-slot:no-data>
      <p class="red--text">Não há registros a serem exibidos!</p>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} de {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
  import AuditDetails from './AuditDetails';
  import AuditService from '../services/Audit';
  import CrudDataTableMixin from '../mixins/CrudDataTableMixin';

  export default {
    name: 'audits',
    mixins: [CrudDataTableMixin],
    components: {
      AuditDetails
    },
    created() {
        this.getDataListFromApi();
    },
    data() {
      return {
        service: AuditService,
        dialogAuditDetails: false,
        loadingDataList: false,
        nameCollection: 'collection',
        headers: [
          { text: '#', value: 'index', width: 50, sortable: false },
          { text: 'ID', value: 'auditable_id' },
          { text: 'Tipo de dado', value: 'auditable_type' },
          { text: 'Ação', value: 'event' },
          { text: 'Usuário', value: 'user', sortable: false },
          { text: 'Modificado em', value: 'updated_at' },
          { text: 'Ações', value: 'actions', sortable: false, width: 150 }
        ],
        filters: {
          search: '',
          page: '',
          sortBy: '',
          sortOrder: '',
          per_page: '',
          event: '',
          auditable_type: '',
          user_id: '',
          from_date: '',
          to_date: ''
        },
        events: {},
        auditable_types: []
      }
    },
    methods: {
      getDataListFromApi() {
        this.loadingDataList = true;
        AuditService.dataList().then(response => {
          Object.keys(response.data).forEach(key => {
            if (this[key] != undefined) {
              this[key] = response.data[key];
            }
          });
        }).finally(() => this.loadingDataList = false);
      },
      auditableType(auditable_type) {
        return this.auditable_types.find(type => type.model == auditable_type);
      },
      expandDetails(audit) {
        this.dialogAuditDetails = true;
        this.$nextTick(() => {
          this.$refs.auditDetails.setAudit(audit);
        });
      },
      closeExpandDetails() {
        this.dialogAuditDetails = false;
        this.$refs.auditDetails.setAudit(null);
      }
    },
    computed: {
      eventList() {
        return Object.keys(this.events).map(key => {
          return {
            value: key,
            text: this.events[key]
          }
        });
      }
    }
  }
</script>