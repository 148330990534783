import Service from './Service';

class Dashboard extends Service {
    constructor() {
        super('dashboards');
    }

    dataList() {
        return this.axios.get(`${this.base}/dataList`);
    }

    async dashboardClear(data) {
        try {
            let res = null;
            if (data && data.id) {
                res = await this.axios.get(`${this.base}/clear/${data.id}`);
            } else {
                res = await this.axios.post(`${this.base}/clear`);
            }

            return res;
        } catch (e) {
            console.log(e);
        }
    }
}

export default new Dashboard();
