<template>
  <v-overlay :value="showLogin">
    <v-row align="center" justify="center" style="height: 100vh">
      <v-col cols="12" lg="7" md="7" sm="7" xs="12">
        <v-card light flat outlined>
          <v-card-title>
            <v-row justify="space-around">
              <v-avatar size="96" class="mt-n12">
                <img aspect-ratio="16/9" v-if="commonUser"
                  :src="commonUser.image" :alt="commonUser ? commonUser.name : 'Avatar Image'" />

                <img aspect-ratio="16/9" v-else
                  src="../assets/avatar.png" :alt="commonUser ? commonUser.name : 'Avatar Image'" />
              </v-avatar>
            </v-row>
          </v-card-title>
          
          <v-card-text>
            <v-form @submit.prevent="submit">
              <div v-if="formLogin">
              <v-row justify="space-around">
                <v-col cols="12" v-if="!commonUser">
                  <v-text-field v-model="form.username"
                    autofocus label="Nome de usuário" :error-messages="usernameErrors"
                    @input="$v.form.username.$touch()" @blur="$v.form.username.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="10" v-else>
                  <span
                    class="title font-weight-light"
                    style="font-weight: 300!important; font-family: Roboto,sans-serif!important; font-size: 1.5625rem!important;">
                    {{ commonUser.name }}
                    <v-icon color="green" small @click="changeUser">mdi-refresh-circle</v-icon>
                  </span>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="form.password" label="Senha" type="password" required
                    :error-messages="passwordErrors"
                    @input="$v.form.password.$touch()" @blur="$v.form.password.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-row class="text-center">
                    <v-col cols="12">
                      <v-btn type="submit" rounded color="green" class="white--text" block
                        :loading="loading" :disabled="$v.$invalid.form">Entrar</v-btn>
                    </v-col>

                    <v-col cols="12">
                      <v-btn rounded color="gray" @click.prevent="close" :disabled="loading" block>Cancelar</v-btn>
                    </v-col>
                    <v-col cols="12">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text small @click="formLogin = false" class="mt-1" v-bind="attrs" v-on="on" style="padding: 0; min-width: 0;">
                          <span class="caption">Esqueci minha senha</span>
                        </v-btn>
                      </template>
                      <span>Apenas usuários externos</span>
                    </v-tooltip>
                  </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-else>
            <v-row justify="space-around">
              <v-col cols="12">
                <v-text-field v-model="email" autofocus label="Email"></v-text-field>
              
                <v-alert border="left" colored-border color="orange" icon="mdi-alert-octagon" elevation="1">
                  <strong>Importante:</strong> A recuperação de senha está disponível apenas para usuários externos <br> da Infra S.A. Se você é um colaborador interno, por favor, entre em contato com o suporte de TI.
                </v-alert>
         
              </v-col>
              <v-col cols="12">
                <v-row class="text-center">
                  <v-col cols="12">
                    <v-btn type="submit" rounded color="green" class="white--text" block :loading="loading">Enviar nova senha por e-mail</v-btn>
                  </v-col>  

                  <v-col cols="12">
                    <v-btn rounded color="gray" @click.prevent="formLogin = true" :disabled="loading" block>Cancelar</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-img contain :aspect-ratio="12" src="../assets/logo.png"></v-img>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, minLength } from 'vuelidate/lib/validators';
  import AuthService from '../services/AuthService';
  import Swal from 'sweetalert2';

  export default {
    name: 'LoginForm',
    mixins: [validationMixin],
    props: {
      showLogin: {
        type: Boolean, default: false
      }
    },
    validations: {
      form: {
        username: { required, minLength: minLength(5) },
        password: { required, minLength: minLength(5) }
      },
      // email: { required, minLength: minLength(5) }
    },
    data() {
      return {
        form: {
          username: '',
          password: ''
        },
        email: '',
        formLogin: true,
        loading: false,
        eplLogo: '@/assets/logo.png',
        avatar: '@/assets/avatar.png'
      };
    },
    methods: {
      submit() {
        if(this.formLogin){
        this.$v.$touch();
        this.loading = true;

        AuthService.login(this.form)
          .then(() => window.location.reload(true))
          .catch(error => {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.title,
                text: error.response.data.detail,
                showConfirmButton: true
            });
          })
          .finally(() => this.loading = false);
        } else {
            console.log("Função que vai receber esse email" + this.email);
            
            if (this.email.endsWith('@infrasa.gov.br')) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'E-mail institucional detectado',
                text: 'A recuperação de senha não está disponível para e-mails institucionais. Por favor, entre em contato com o suporte de TI.',
                showConfirmButton: true
              });
              this.loading = false; 
            } else {
              this.$user.resetPasswordAndSendEmail(this.email)
                .then(() => {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'E-mail de redefinição de senha enviado',
                    text: 'Verifique sua caixa de entrada para as próximas instruções.',
                    showConfirmButton: true
                  });
                })
                .catch(error => {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Erro ao enviar e-mail',
                    text: error.response.data.detail || 'Não foi possível enviar o e-mail de redefinição de senha.',
                    showConfirmButton: true
                  });
                })
                .finally(() => this.loading = false);
            }
          }
      },
      close() {      
        this.$v.$reset();
        this.form.username = '';
        this.form.password = '';
        this.formLogin = true;
        this.$emit('update:showLogin', false);
      }
    },
    computed: {
      usernameErrors() {
        const errors = [];
        if (!this.$v.form.username.$dirty) return errors;
        !this.$v.form.username.required && errors.push('Campo obrigatório');
        !this.$v.form.username.minLength && errors.push('Mínimo de 5 caracteres');
        return errors;
      },
      // emailErrors() {
      //   const errors = [];
      //   if (!this.$v.email.$dirty) return errors;
      //   !this.$v.email.required && errors.push('Campo obrigatório');
      //   return errors;
      // },
      passwordErrors() {
        const errors = [];
        if (!this.$v.form.password.$dirty) return errors;
        !this.$v.form.password.required && errors.push('Campo obrigatório');
        !this.$v.form.password.minLength && errors.push('Mínimo de 5 caracteres');
        return errors;
      },
      commonUser() {
        return false;
      }
    }  
  };
</script>
