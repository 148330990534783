const state = {
  showHeaderBar: true,
};

const getters = {
  showHeaderBar: (state) => state.showHeaderBar,
};

const actions = {
  toggleHeaderBar({ commit, getters }) {
    commit("setHeaderBar", !getters.showHeaderBar);
  },
};

const mutations = {
  setHeaderBar(state, value) {
    state.showHeaderBar = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
