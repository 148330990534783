<template>
  <v-data-table
    dense
    :headers="headers"
    :items="getItemsFromCollection"
    :options.sync="options"
    :server-items-length="collection.meta.total || 0"
    class="elevation-1"
    :footer-props="configDataTable"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Apps Qlik</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-select v-model="filters.host_id" :items="hosts" :loading="loadingHosts"
            item-text="name" item-value="id" :clearable="true"
            label="Filtrar por host" hide-details no-data-text="Não há dados disponíveis"
            ></v-select>
        <v-spacer></v-spacer>

        <v-text-field @change="filters.search = $event" :value="filters.search"
          append-icon="mdi-magnify" label="Pesquisar" hide-details></v-text-field>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialogForm" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="addData('dialogAppsForm')">
              Adicionar
            </v-btn>
          </template>

          <AppsForm
            ref="dialogAppsForm"
            :hosts="hosts"
            @dataFormSaved="dataSaved"
            @cancelSavingForm="closeDialogForm" />
        </v-dialog>
      </v-toolbar>

      <v-progress-linear indeterminate color="primary" v-show="loading"></v-progress-linear>
    </template>

    <template v-slot:item.host="{ item }">
      <div>{{ item.relationships.host.name }}</div>
      <div class="caption">{{ item.relationships.host.dns }}</div>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="editData(item, 'dialogAppsForm')">
            <v-icon small class="mr-2" color="dark">
              mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="removeData(item)">
            <v-icon small class="mr-2" color="red">
              mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>

    <template v-slot:no-data>
      <p class="red--text">Não há registros a serem exibidos!</p>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} de {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
  import AppsForm from './AppsForm';
  import AppService from '../../services/QlikApp';
  import HostService from '../../services/QlikHost';
  import CrudDataTableMixin from '../../mixins/CrudDataTableMixin';

  export default {
    name: 'apps',
    mixins: [CrudDataTableMixin],
    components: {
      AppsForm
    },
    created() {
      this.getHostsDataFromApi();
    },
    data() {
      return {
        service: AppService,
        dialogForm: false,
        nameCollection: 'collection',
        headers: [
          { text: '#', value: 'index', width: 50, sortable: false },
          { text: 'Nome', align: 'start', value: 'name' },
          { text: 'ID do App', value: 'app' },
          { text: 'Host', value: 'host' },
          { text: 'Ações', value: 'actions', sortable: false, width: 150 }
        ],
        filters: {
          search: '',
          host_id: '',
          page: '',
          sortBy: '',
          sortOrder: '',
          per_page: ''
        },
        hosts: [],
        loadingHosts: false
      }
    },
    methods: {
      getHostsDataFromApi() {
        this.loadingHosts = true;
        HostService.getAll()
          .then(response => this.hosts = response.data.data.map((r, idx) => this.getDataFromModelApi(r, idx)))
          .finally(() => this.loadingHosts = false);
      }
    }
  }
</script>