import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store/index';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

import Home from './views/Home';
import PowerBiIframe from './views/PowerBiIframe'
import Qlik from './views/Qlik';
import QlikHosts from './components/hosts/Hosts';
import QlikObjects from './components/objects/Objects';
import QlikApps from './components/apps/Apps';
import Dashboards from './components/dashboards/Dashboards';
import Groups from './components/groups/Groups';
import Dashboard from './views/Dashboard';
import Users from './views/Users';
import ImageManager from './views/ImageManager';
import Audits from './views/Audits';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', name: 'home', component: Home, meta: { requiresAuth: false } },
    { path: '/seguranca-viaria', name: 'seguranca-viaria', component: PowerBiIframe, meta: { requiresAuth: false } }, // rota para powerBi

    {
      path: '/qlik', component: Qlik,
      children: [
        { path: '/', name: 'adminQlik', component: Dashboards, meta: { requiresAuth: true } },
        { path: 'dashboards', name: 'adminDashboards', component: Dashboards, meta: { requiresAuth: true } },
        { path: 'hosts', name: 'adminHosts', component: QlikHosts, meta: { requiresAuth: true } },
        { path: 'objects', name: 'adminObjects', component: QlikObjects, meta: { requiresAuth: true } },
        { path: 'apps', name: 'adminApps', component: QlikApps, meta: { requiresAuth: true } },
        { path: 'groups', name: 'adminGroups', component: Groups, meta: { requiresAuth: true } },
        { path: 'audits', name: 'adminAudits', component: Audits, meta: { requiresAuth: true } }
      ]
    },
    { path: '/dashboard/:id/:embed?', name: 'dashboard', component: Dashboard, props: true, meta: { requiresAuth: false } },
    { path: '/image-manager', name: 'image-manager', component: ImageManager, meta: { requiresAuth: true } },
    { path: '/users', name: 'users', component: Users, meta: { requiresAuth: true } },
    { path: '*', component: Home, meta: { requiresAuth: false } }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/checkAuth']) {
    Swal.fire({ title: 'Acesso negado!', icon: 'error', text: 'É necessário logar para acessar a página solicitada.' });
    router.push('/');
    return;
  }

  next();
});

export default router;
