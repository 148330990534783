import Service from './Service';

class DashboardObject extends Service {
    constructor() {
        super('dashboard-objects');
    }

    updateOrders(data) {
        return this.axios.post(`${this.base}/updateOrders`, data);
    }
}

export default new DashboardObject();
