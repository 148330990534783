<template>
  <div>
    <v-tabs v-model="tab" centered icons-and-text>
      <v-tab v-for="(tab, index) in items" :key="index" :to="tab.to">
        {{ tab.name }}
        <v-icon> {{ tab.icon }} </v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items class="pt-5">
      <router-view></router-view>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'Qlik',
  data() {
    return {
      tab: null,
      items: [
        { icon: 'mdi-view-dashboard', name: 'Dashboards', to: { name: 'adminDashboards' } },
        { icon: 'mdi-apps', name: 'Objetos Qlik', to: { name: 'adminObjects' } },
        { icon: 'mdi-sitemap', name: 'Apps Qlik', to: { name: 'adminApps' } },
        { icon: 'mdi-server', name: 'Hosts Qlik', to: { name: 'adminHosts' } },
        { icon: 'mdi-group', name: 'Grupos', to: { name: 'adminGroups' } },
        { icon: 'mdi-database-search', name: 'Auditoria', to: { name: 'adminAudits' } }
      ]
    };
  }
};
</script>
