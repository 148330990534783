<template>
  <v-data-table dense :headers="headers" :items="getItemsFromCollection" :options.sync="options"
    :server-items-length="collection.meta.total || 0" class="elevation-1" :footer-props="configDataTable">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Usuários</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-text-field @change="filters.search = $event" :value="filters.search" append-icon="mdi-magnify"
          label="Pesquisar" hide-details></v-text-field>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialogForm" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="addData('dialogObjectsForm')">
              Adicionar
            </v-btn>
          </template>

          <UserForm ref="dialogObjectsForm" :hosts="hosts" :apps="apps" @dataFormSaved="dataSaved"
            @cancelSavingForm="closeDialogForm" />
        </v-dialog>
      </v-toolbar>

      <ManageGroups :dialogGroups.sync="dialogGroups" :editedGroupsUser.sync="editedGroupsUser"
        @groupsSaved="groupsSaved" />

      <v-progress-linear indeterminate color="primary" v-show="loading"></v-progress-linear>
    </template>

    <template v-slot:item.is_admin="{ item }">
      <span v-if="item.is_admin">Administrador</span>
      <span v-else> Normal</span>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="grey lighten-1" dark class="mr-2"
            @click="openManageGroups(item)">mdi-group</v-icon>
        </template>
        <span>Associação de grupos</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" :disabled="$store.getters['auth/user'].username == item.username"
            :color="item.is_admin ? 'green' : 'grey lighten-1'" dark
            @click="updateAdminMode(item)">mdi-key-change</v-icon>
        </template>
        <span>Alterar tipo de usuário</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs"
            :disabled="$store.getters['auth/user'].username == item.username || item.email.endsWith('@infrasa.gov.br')"
            v-on="on" @click="removeData(item)">
            <v-icon small class="mr-2" color="red">
              mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Remover usuário</span>
      </v-tooltip>
    </template>

    <template v-slot:no-data>
      <p class="red--text">Não há registros a serem exibidos!</p>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} de {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import ManageGroups from '../components/groups/ManageGroups';
import UserService from '../services/User';
import UserForm from './UserForm';
import CrudDataTableMixin from '../mixins/CrudDataTableMixin';
import Swal from 'sweetalert2';

export default {
  name: 'users',
  mixins: [CrudDataTableMixin],
  components: {
    ManageGroups,
    UserForm
  },
  data() {
    return {
      service: UserService,
      dialogForm: false,
      nameCollection: 'collection',
      headers: [
        { text: '#', value: 'index', width: 50, sortable: false },
        { text: 'Colaborador', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Usuário de rede', value: 'username' },
        { text: 'Privilégio', value: 'is_admin', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false, width: 150 }
      ],
      dialogGroups: false,
      editedGroupsUser: {
        user_id: null,
        groups: []
      },
      filters: {
        search: '',
        page: '',
        sortBy: '',
        sortOrder: '',
        per_page: ''
      }
    }
  },
  methods: {
    filterOnlyLowerText(value, search) {
      return (value && search && typeof value === 'string' && value.toString().toLocaleLowerCase().indexOf(search) > -1);
    },
    openManageGroups(item) {
      item.selectedGroups = item.groups.map(group => {
        return {
          id: group.id,
          name: group.name
        };
      });

      this.editedGroupsUser = Object.assign({}, item);
      this.dialogGroups = true;
    },
    updateAdminMode(item) {
      this.$store.dispatch('User/toggleAdmin', item)
        .then(() => this.getDataFromApi())
        .catch(error => {
          Swal.fire({
            title: 'Houve um erro ao tentar fazer essa operação!',
            text: error.response.data.error,
            icon: 'warning'
          });
        });
    },
    groupsSaved() {
      this.dialogGroups = false;
      this.editedGroupsUser = {
        user_id: null,
        groups: []
      };

      this.getDataFromApi();
    }
  }
}
</script>