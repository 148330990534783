import store from '../store';
import Service from './Service';

class AuthService extends Service {
    login(credentials) {
        return this.axios.post('login', credentials)
            .then(response => {
                store.dispatch('auth/setUserAuthenticated', response.data);
                return response;
            });
    }

    logout() {
        return store.dispatch('auth/logoutUserAuthenticated');
    }
}

export default new AuthService();
