import Service from './Service';

class Audit extends Service {
    constructor() {
        super('audits');
    }

    dataList() {
        return this.axios.get(`${this.base}/dataList`);
    }
}

export default new Audit();
