export default {
    name: null,
    description: null,
    image_url: null,
    theme: null,
    icon: null,
    published_at: null,
    group_id: null,
    app_id: null
}
