<template>
  <v-form v-model="formValid" ref="formValid" @submit.prevent="save">
    <v-card>
      <v-card-title v-if="formTitle">
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select v-model="host_id" :items="hosts" label="Host Qlik"
                item-text="name" item-value="id" outlined hide-details dense></v-select>
            </v-col>

            <v-col cols="12">
              <v-autocomplete v-model="resource.app_id" :items="apps" :disabled="!host_id"
                item-text="name" item-value="id" :clearable="true" :filter="filterDataAutocomplete"
                label="App Qlik" hide-details no-data-text="Não há dados disponíveis" outlined dense
                ></v-autocomplete>
            </v-col>

            <v-col cols="12">
                <v-autocomplete v-model="resource.group_id" :items="groups"
                    item-text="name" item-value="id" :clearable="true" :filter="filterDataAutocomplete"
                    label="Grupo" hide-details no-data-text="Não há dados disponíveis" outlined dense
                    ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Nome" v-model="resource.name" hide-details dense></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-text-field v-model="resource.icon" label="Ícone (mdi-icon)" hide-details dense></v-text-field>
                <a href="http://code.meta-platform.com/assets/mdi/preview.html" target="_blank" class="caption mt-n4">
                    Ver icones disponíveis
                </a>
            </v-col>

            <v-col cols="12">
                <v-text-field v-model="resource.image_url" label="URL da Imagem de capa" hide-details dense></v-text-field>
                <a href="https://www.pexels.com" target="_blank" class="caption mt-n4">
                    Ver imagens disponíveis
                </a>
            </v-col>

            <v-col cols="12">
                <v-textarea label="Descrição" v-model="resource.description" hide-details rows="3" dense></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-select v-model="resource.theme" :items="themes" label="Tema"
                item-text="text" item-value="id" outlined hide-details dense></v-select>
            </v-col>

            <v-col cols="12">
                <v-switch v-model="resource.publish" label="Publicar" color="primary"
                    :value="true" hide-details class="float-right"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text class="mb-2" @click="cancelSaving" :disabled="loading.save">
          Cancelar
        </v-btn>
        <v-btn color="primary" text class="mb-2" type="submit" :loading="loading.save" :disabled="loading.save || !formValid">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import DashboardModel from '../../models/dashboard';
  import DashboardService from '../../services/Dashboard';
  import CrudDataFormMixin from '../../mixins/CrudDataFormMixin';

  export default {
    name: 'dashboards-form',
    mixins: [CrudDataFormMixin],
    props: {
      groups: { type: Array, default: () => [] },
      hosts: { type: Array, default: () => [] },
      apps: { type: Array, default: () => [] }
    },
    created() {
      this.loadDataList();
    },
    data() {
      return {
        labels: {
          formTitleAdd: 'Adicionar dashboard',
          formTitleEdit: 'Editar dashboard'
        },
        model: { ...DashboardModel },
        resource: { ...DashboardModel },
        service: DashboardService,
        loading: {
          save: false
        },
        host_id: null,
        themes: []
      }
    },
    methods: {
      loadDataList() {
        DashboardService.dataList()
          .then(response => {
            Object.keys(response.data).forEach(key => {
              if (this[key] !== undefined) {
                this[key] = response.data[key];
              }
            });
          });
      }
    },
    watch: {
      hosts: {
        handler(hosts) {
          if (hosts.length == 1) {
            this.host_id = hosts[0].id;
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
