<template>
  <div>
    <p>QdtComponent</p>
    <p>Type: {{ type }}</p>
    <p>Props: {{ props }}</p>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  name: 'QlikInterface',
  props: {
    type: {
      type: String,
      required: true
    },
    app: {
      type: Object,
      default: () => null
    },
    props: {
      type: Object,
      required: true
    }
  },
  mounted() {
    if (this.app) {
      this.getQtdComponent();
    }
  },
  data() {
    return {
      qdtElement: null
    };
  },
  methods: {
    getQtdComponent() {
      this.app.render(this.type, this.props, this.$el)
        .then(response => {
          if (response.qVizPromise) {
            response.qVizPromise.then(qviz => this.qdtElement = qviz);
          }
        });
    },
    exportByTabSeparator() {
      this.qdtElement
        .exportData({ format: 'CSV_T', state: 'A' })
        .then(link => window.open(link))
        .catch(err => this.messageFailExport(err));
    },
    exportByCommaSeparator() {
      this.qdtElement
        .exportData({ format: 'CSV_C', state: 'A' })
        .then(link => this.handlerExportUrl(link))
        .catch(err => this.messageFailExport(err));
    },
    exportToExcel() {
      this.qdtElement
        .exportData({ format: 'OOXML', state: 'A' })
        .then(link => this.handlerExportUrl(link))
        .catch(err => this.messageFailExport(err));
    },
    exportToPdf() {
      this.qdtElement
        .exportPdf({ documentSize: 'a4', aspectRatio: 2, orientation: 'landscape' })
        .then(link => window.open(link))
        .catch(err => this.messageFailExport(err));
    },
    exportToPng() {
      this.qdtElement
        .exportImg({ format: 'png', height: 720, width: 1280 })
        .then(link => window.open(link))
        .catch(err => this.messageFailExport(err));
    },
    handlerExportUrl(link) {
      let url = link.split(/(?=http.?:\/\/)(.*)(?=http.?:\/\/)/);
      if (url.length) {
        window.open(url[(url.length-1)]);
      } else {
        Swal.fire({ title: 'Atenção!', icon: 'warning', text: 'Houve um erro ao exportar dados. Tente novamente mais tarde.' });
      }
    },
    messageFailExport(err) {
      console.log('EXPORT ERROR', err);
      Swal.fire({ title: 'Atenção!', icon: 'warning', text: 'Não foi possível exportar os dados. Tente novamente mais tarde.' });
    }
  },
  watch: {
    app: {
      handler() {
        if (this.app && this.$el) {
          this.getQtdComponent();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
