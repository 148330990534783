import axios from 'axios';
const state = {
  loadingUser: false,
  user: null,
  users: null,
  showForm: false,
  authAlert: null,
  sessionKey: 'loggedUser',
  localkey: 'commonUser',
  adminMode: false
};

const getters = {
  user: (state) => state.user,
  users: (state) => state.users,
  isAdmin: (state) =>
    state.user.data.attributes.is_admin ||
    state.user.data.attributes.username == 'tacio.brito',
  adminMode: (state, getters) => state.adminMode && getters.isAdmin,
  authorizedGroups: (state) => state.user ? state.user.data.attributes.groups : [],
  commonUser: (state) => {
    return JSON.parse(localStorage.getItem(state.localkey));
  },
  userCommonName: (state) => {
    var pieces = state.user.data.attributes.name.split(' ');
    return pieces[0] + ' ' + pieces[pieces.length - 1];
  },
  loadingUser: (state) => state.loadingUser,
  showLoginForm: (state) => state.showForm,
  authAlert: (state) => state.authAlert,
  bearerToken: (state) => {
    let token = sessionStorage.getItem(state.sessionKey);
    if (token) {
      return JSON.parse(token).data.attributes.access_token;
    }
    return null;
  },
  isAuthenticated: (state) => {
    var result = state.user || !!sessionStorage.getItem(state.sessionKey);

    if (result) {
      state.user = JSON.parse(sessionStorage.getItem(state.sessionKey));
    }

    return result;
  },
};

const actions = {
  fetchUser({ commit, dispatch }, data) {
    commit('setLoadingUser', true);

    axios
      .post(process.env.VUE_APP_ROOT_API_BACKEND + '/login', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        commit('setUser', res.data);
        dispatch('toggleLoginForm');
      })
      .catch((error) => {
        console.log(error);
        commit('setAuthAlert', {
          title: 'Falha na autenticação',
          description: 'Revise suas credenciais e tente novamente',
          visible: true,
        });
      })
      .finally(commit('setLoadingUser', false));
  },
  fetchUsers({ commit, getters }) {
    commit('setLoadingUser', true);
    axios
      .get(process.env.VUE_APP_ROOT_API_BACKEND + '/users', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getters.bearerToken,
        },
      })
      .then((res) => {
        commit('setUsers', res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(commit('setLoadingUser', false));
  },
  toggleAdmin({ getters }, data) {
    return axios.patch(
        process.env.VUE_APP_ROOT_API_BACKEND + '/users/' + data.id,
        { _method: 'patch', is_admin: !data.is_admin },
        { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getters.bearerToken } }
      )
      .catch((error) => console.log(error));
  },
  updateUserGroups({ getters }, data) {
    return axios.patch(
        process.env.VUE_APP_ROOT_API_BACKEND + '/users/' + data.id,
        { _method: 'patch', groups: data.groups },
        { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + getters.bearerToken } }
      )
      .catch((error) => console.log(error));
  },
  toggleAdminMode({ commit, getters }) {
    commit('setAdminMode', !getters.adminMode);
  },
  toggleLoginForm({ commit, getters }) {
    commit('setShowForm', !getters.showLoginForm);
  },
  closeAuthAlert({ commit }) {
    commit('setAuthAlert', { visible: false });
  },
  logout({ commit }) {
    commit('unsetUser');
  },
};

const mutations = {
  setLoadingUser(state, value) {
    state.loadingUser = value;
  },
  setUser(state, value) {
    state.user = value;
    if (!value.data.attributes.access_token) return;
    sessionStorage.setItem(state.sessionKey, JSON.stringify(value));
    localStorage.setItem(
      state.localkey,
      JSON.stringify({
        username: value.data.attributes.username,
        name: name,
        image:
          process.env.MIX_USER_IMAGE_API_URL +
          '/' +
          value.data.attributes.username,
      })
    );
  },
  setUsers(state, value) {
    state.users = value;
  },
  setAdminMode(state, value) {
    state.adminMode = value;
  },
  setShowForm(state, value) {
    state.showForm = value;
  },
  setAuthAlert(state, value) {
    state.authAlert = value;
  },
  unsetUser(state) {
    state.user = null;
    sessionStorage.removeItem(state.sessionKey);
  },

  clearUser(state) {
    state.user = null;
    localStorage.removeItem(state.localkey);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
