<template>
  <v-form v-model="formValid" ref="formValid" @submit.prevent="beforeSave">
    <v-card>
      <v-card-title>
        <span class="headline">Vincular no painel ({{ folder }}) 
          {{ appName }}</span>
      </v-card-title>

      <v-divider class="my-5 mx-5"></v-divider>

      <v-card-text>
        <v-select v-model="filters.host_id" :disabled="true" :loading="loading.hosts"
          item-text="name" item-value="id" :items="hosts" label="Host Qlik" outlined>
        </v-select>

        <v-autocomplete v-model="filters.app_id" :disabled="true" :loading="loading.apps"
          :filter="customFilterData" item-text="name" item-value="id" :items="apps" label="App Qlik" outlined>
        </v-autocomplete>

        <v-select v-model="resource.folder_id" :disabled="!filters.app_id || !folders.length"
          item-text="name" item-value="id" :items="folders" label="Pasta" outlined>
        </v-select>

        <v-autocomplete v-model="resource.qlik_object_id" :disabled="!resource.folder_id || !apps.length" :loading="loading.objects"
          :filter="customFilterData" item-text="name" item-value="id" :items="objects" label="Objetos Qlik" outlined>
        </v-autocomplete>

        <v-card class="mb-3 pa-3 " v-if="resource.qlik_object_id && objectId" outlined>
          <center>
            Visualizar:
            <Maximizar :app="app" type="QdtViz" :objectId="objectId" :height="400" />
          </center>
        </v-card>

        <v-slider v-if="resource.qlik_object_id" v-model="resource.width" color="orange" :label="`Largura do objeto: ( ${resource.width} )`"
          min="1" max="12" thumb-label></v-slider>

        <v-slider v-if="resource.qlik_object_id" v-model="resource.height" color="orange" :label="`Altura do objeto: ( ${resource.height} )`"
          min="1" max="12" thumb-label></v-slider>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="darken-1" text @click="cancelSaving">
          Cancelar
        </v-btn>

        <v-btn color="green darken-1" type="submit" text :disabled="!resource.qlik_object_id" :loading="loading.save || !formValid">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import Maximizar from '../QlikComponents/Maximizar';

  import QlikHostService from '../../services/QlikHost';
  import QlikAppService from '../../services/QlikApp';
  import QlikObjectService from '../../services/QlikObject';
  import DashboardObject from '../../services/DashboardObject';

  import dashboardObjectsModel from '../../models/dashboard_objects';
  import CrudDataFormMixin from '../../mixins/CrudDataFormMixin';

  export default {
    name: 'DashboardAddObject',
    mixins: [CrudDataFormMixin],
    components: {
      Maximizar
    },
    props: {
      dashboard: { type: Object, required: true },
      app: { type: Object, required: true },
      folders: { type: Array, default: () => [] },
      folder: { type: String, default: null }
    },
    mounted() {
      this.loadHosts();
    },
    data() {
      return {
        resource: { ...dashboardObjectsModel },
        model: { ...dashboardObjectsModel },
        service: DashboardObject,
        labels: {
          formTitleAdd: 'Vincular novo objeto Qlik',
          formTitleEdit: 'Editar objeto Qlik'
        },
        filters: {
          app_id: null,
          host_id: null
        },
        loading: {
          hosts: false,
          apps: false,
          objects: false,
          save: false
        },
        hosts: [],
        apps: [],
        objects: []
      };
    },
    methods: {
      loadHosts() {
        this.loading.hosts = true;
        QlikHostService.getAll()
          .then(response => this.hosts = response.data.data.map(
            (resource, index) => this.getDataFromModelApi(resource, (index+1)))
          )
          .finally(() => this.loading.hosts = false);
      },
      loadApps() {
        this.loading.apps = true;
        QlikAppService.getAll(`?host_id=${this.filters.host_id}`)
          .then(response => this.apps = response.data.data.map(
            (resource, index) => this.getDataFromModelApi(resource, (index+1)))
          )
          .finally(() => this.loading.apps = false);
      },
      loadObjects() {
        this.loading.objects = true;
        QlikObjectService.getAll(`?app_id=${this.filters.app_id}`)
          .then(response => this.objects = response.data.data.map(
            (resource, index) => this.getDataFromModelApi(resource, (index+1)))
          )
          .finally(() => this.loading.objects = false);
      },
      beforeSave() {
        this.save();
      },
      customFilterData(item, queryText) {
        const text = item.name.toLowerCase();
        const searchText = queryText.toLowerCase();

        return text.indexOf(searchText) > -1;
      }
    },
    computed: {
      appId() {
        return this.dashboard.relationships.app.app;
      },
      appName() {
        return this.dashboard.relationships.app.name;
      },
      appHost() {
        return this.dashboard.relationships.app.relationships.host.dns;
      },
      objectId() {
        if (this.resource.qlik_object_id) {
          let object = this.objects.find(resource => resource.id == this.resource.qlik_object_id);
          return object ? object.object : null;
        }
        return null;
      }
    },
    watch: {
      hosts() {
        let host = this.hosts.find(resource => resource.dns == this.appHost);
        if (host) {
          this.filters.host_id = host.id;
        }
      },
      apps() {
        let app = this.apps.find(resource => resource.app == this.appId);
        if (app) {
          this.filters.app_id = app.id;
        }
      },
      'filters.host_id': function(val) {
        if (val) {
          this.loadApps();
        }
      },
      'filters.app_id': function(val) {
        if (val) {
          this.loadObjects();
        }
      },
      folder: {
        handler(val) {
          if (val) {
            let folder = this.folders.find(resource => resource.alias == val);
            if (folder) {
              this.resource.folder_id = folder.id;
            }
          }
        },
        deep: true,
        immediate: true
      }
    }
  };
</script>

<style scope>
  input {
    font-size: 16px;
  }  
</style>
