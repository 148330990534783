<template>
  <v-form v-model="formValid" ref="formValid" @submit.prevent="save">
    <v-card>
      <v-card-title v-if="formTitle">
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Nome" v-model="resource.name"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Username" v-model="resource.username"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Email" v-model="resource.email"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text class="mb-2" @click="cancelSaving" :disabled="loading.save">
          Cancelar
        </v-btn>
        <v-btn color="primary" text class="mb-2" type="submit" :loading="loading.save" :disabled="loading.save || !formValid">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import UserModel from '../models/user';
  import UserService from '../services/User';
  import CrudDataFormMixin from '../mixins/CrudDataFormMixin';

  export default {
    name: 'objects-form',
    mixins: [CrudDataFormMixin],
    props: {
      hosts: { type: Array, default: () => [] },
      apps: { type: Array, default: () => [] }
    },
    data() {
      return {
        labels: {
          formTitleAdd: 'Adicionar objeto',
          formTitleEdit: 'Editar objeto'
        },
        model: { ...UserModel },
        resource: { ...UserModel },
        service: UserService,
        loading: {
          save: false
        },
        host_id: null
      }
    },
    watch: {
      hosts: {
        handler(hosts) {
          if (hosts.length == 1) {
            this.host_id = hosts[0].id;
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>
