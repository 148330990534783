<template>
  <v-toolbar flat color="white">
    <v-dialog v-model="_dialogGroups" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Associação de grupos</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-combobox v-model="editedGroupsUser.selectedGroups" :items="groups"
                  item-text="name" item-value="item" label="Adicionar grupos" multiple chips></v-combobox>
              </v-col>              
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="save" :loading="$store.getters.savingGroup">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
  import UserService from '../../services/User';
  import GroupService from '../../services/Group';

  export default {
    name: 'ManageGroups',
    props: {
      dialogGroups: { type: Boolean, required: true },
      editedGroupsUser: { type: Object, required: true }
    },
    mounted() {
      this.getGroupsDataFromApi();
    },
    data() {
      return {
        loading: {
          groups: false,
          save: false
        },
        groups: []
      }
    },
    methods: {
      close() {
        this._dialogGroups = false;
        this.$nextTick(() => {
          this._editedGroupsUser = Object.assign({}, this.defaultItem);
        });
      },
      save() {
        this.loading.save = true;
        UserService.update(this._editedGroupsUser.id, { groups: this._editedGroupsUser.selectedGroups.map(group => group.id) })
          .then(() => this.$emit('groupsSaved'))
          .finally(() => this.loading.save = false);

        this.close();
      },
      getGroupsDataFromApi() {
        this.loading.groups = true;
        GroupService.getAll()
          .then(response => this.groups = response.data.data.map((r, idx) => this.getDataFromModelApi(r, idx)))
          .finally(() => this.loading.groups = false);
      }
    },
    computed: {
      _dialogGroups: {
        get() {
          return this.dialogGroups;
        },
        set(value) {
          this.$emit('update:dialogGroups', value);
        }
      },
      _editedGroupsUser: {
        get() {
          return this.editedGroupsUser;
        },
        set(value) {
          this.$emit('update:editedGroupsUser', value);
        }
      }
    },
    watch: {
      _dialogGroups(val) {
        val || this.close();
      }
    }
  }
</script>
