<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="dialog = true">mdi-fullscreen</v-icon>
      </template>
      <span>Maximizar</span>
    </v-tooltip>

    <v-dialog v-model="dialog" class="ma-12" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark style="background-color: #004E85;" color="darken-4">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title> Visualização maximizada</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-10">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="10" class="kpi-height-ajust">
              <QlikInterface v-if="dialog" ref="qlikRef" :type="type" :props="localConfig" :app="app" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import QlikInterface from './QlikInterface';

export default {
  name: 'Maximizar',
  props: ['title', 'height', 'objectId', 'app', 'type'],
  computed: {
    localConfig() {
      return {
        id: this.objectId,
        height: this.height + 'px'
      };
    }
  },
  components: {
    QlikInterface
  },
  data() {
    return {
      dialog: false
    };
  }
};
</script>

<style>
  .kpi-height-ajust {
    line-height: 1;
  }
</style>
