<template>
  <v-col cols="12">
    <v-form v-model="formValid" ref="formValid" @submit.prevent="customSave">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-checkbox class="mx-1" v-model="resource.export_pdf" label="PDF" dense hide-details></v-checkbox>
            <v-checkbox class="mx-1" v-model="resource.export_xls" label="XLS" dense hide-details></v-checkbox>
            <v-checkbox class="mx-1" v-model="resource.export_csv" label="CSV" dense hide-details></v-checkbox>
            <v-checkbox class="mx-1" v-model="resource.export_image" label="PNG" dense hide-details></v-checkbox>
            <v-checkbox class="mx-1" v-model="resource.show_info" label="INFO" dense hide-details></v-checkbox>
            <v-checkbox class="mx-1" v-model="resource.outlined" label="BORDA" dense hide-details></v-checkbox>
            <v-checkbox class="mx-1" v-model="resource.maximize" label="MAXIMIZAR" dense hide-details></v-checkbox>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field outlined label="Largura" v-model="resource.width" dense hide-details></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field outlined label="Altura" v-model="resource.height" dense hide-details></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-select item-text="name" item-value="id" :items="folders" label="Pasta"
            v-model="resource.folder_id" outlined dense hide-details></v-select>
        </v-col>
        
        <v-col cols="12">
          <v-btn small block outlined color="primary" type="submit" :loading="loading.save"
            :disabled="loading.save || loading.remove" hide-details>Aplicar mudanças</v-btn>
        </v-col>

        <v-col cols="12" class="mt-0">
          <v-btn small block outlined color="error" @click.prevent="removeData" :loading="loading.remove"
            :disabled="loading.save || loading.remove" hide-details>Excluir</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
  import dashboardObjectsModel from '../../models/dashboard_objects';
  import CrudDataFormMixin from '../../mixins/CrudDataFormMixin';
  import DashboardObject from '../../services/DashboardObject';

  import Swal from 'sweetalert2';

  export default {
    name: 'dashboard-object-control',
    mixins: [CrudDataFormMixin],
    props: {
      object: {
        type: Object,
        default: () => null
      },
      folders: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        labels: {
          formTitleAdd: 'Adicionar objeto',
          formTitleEdit: 'Editar objeto'
        },
        model: { ...dashboardObjectsModel },
        resource: { ...dashboardObjectsModel },
        service: DashboardObject,
        loading: {
          save: false,
          remove: false
        }
      };
    },
    methods: {
      customSave() {
        if (!this.$refs.formValid.validate()) {
          return;
        }

        this.loading.save = true;
        let request;
        if (this.resource_id) {
          request = this.service.update(this.resource_id, this.resource);
        } else {
          request = this.service.save(this.resource);
        }

        request.then(response => {
          this.resource = this.getDataFromModelApi(response.data);
          this.$emit('dataFormSaved');
        }).finally(() => this.loading.save = false);
      },
      removeData() {
        Swal.fire({
          title: 'Está certo de excluir esse registro?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.service.remove(this.resource.id).then(() => this.$emit('dataRemoved'));
          }
        });
      }
    },
    watch: {
      object: {
        handler() {
          this.resource = this.object;
          this.setResourceId(this.object.id);
        },
        deep: true,
        immediate: true
      }
    }
  };
</script>
