<template>
  <v-data-table
    dense
    :headers="headers"
    :items="getItemsFromCollection"
    :options.sync="options"
    :server-items-length="collection.meta.total || 0"
    class="elevation-1"
    :footer-props="configDataTable"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Dashboards</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-select v-model="filters.host_id" :items="hosts" :loading="loadingHosts"
          item-text="name" item-value="id" :clearable="true"
          label="Filtrar por host" hide-details no-data-text="Não há dados disponíveis"
          ></v-select>
        <v-spacer></v-spacer>

        <v-autocomplete v-model="filters.app_id" :items="apps" :loading="loadingApps"
          item-text="name" item-value="id" :clearable="true" :filter="filterDataAutocomplete"
          label="Filtrar por app" hide-details no-data-text="Não há dados disponíveis"
          ></v-autocomplete>
        <v-spacer></v-spacer>

        <v-text-field @change="filters.search = $event" :value="filters.search"
          append-icon="mdi-magnify" label="Pesquisar" hide-details></v-text-field>

        <v-spacer></v-spacer>
        <v-btn color="warning" @click.prevent="clearCache(null)" :loading="loadingClearCache" :disabled="loadingClearCache" class="mb-2">
          <v-icon>mdi-cached</v-icon> Limpar cache dos dashboards
        </v-btn>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialogForm" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="addData('dialogDashboardsForm')">
              Adicionar
            </v-btn>
          </template>

          <DashboardsForm
            ref="dialogDashboardsForm"
            :groups="groups"
            :hosts="hosts"
            :apps="apps"
            @dataFormSaved="dataSaved"
            @cancelSavingForm="closeDialogForm" />
        </v-dialog>
      </v-toolbar>
      
      <v-dialog v-model="dialogFolders" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title v-if="dashboard">Pastas do dashboard {{ dashboard.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="closeManagerFolders">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <Folders :dashboard="dashboard" class="mx-2 mt-5" />
        </v-card>
      </v-dialog>

      <v-progress-linear indeterminate color="primary" v-show="loading"></v-progress-linear>
    </template>

    <template v-slot:item.image_url="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">Visualizar</span>
        </template>
        <span>
          <v-img width="400" contain :src="item.image_url"></v-img>
        </span>
      </v-tooltip>
    </template>

    <template v-slot:item.group="{ item }">
      <div>{{ item.relationships.group.name }}</div>
    </template>

    <template v-slot:item.icon="{ item }">
      <v-icon>{{ item.icon }}</v-icon>
      {{ item.icon }}
    </template>

    <template v-slot:item.app="{ item }">
      <div v-if="item.relationships.app">{{ item.relationships.app.name }}</div>
      <div class="caption" v-if="item.relationships.app">{{ item.relationships.app.app }}</div>
    </template>

    <template v-slot:item.host="{ item }">
      <div v-if="item.relationships.app">{{ item.relationships.app.relationships.host.name }}</div>
      <div class="caption" v-if="item.relationships.app">{{ item.relationships.app.relationships.host.dns }}</div>
    </template>

    <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="warning"
            @click.prevent="clearCache(item)" :loading="loadingClearCache">mdi-cached</v-icon>
        </template>
        <span>Limpar cache</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small class="mr-2" :color="`${getColorPublishingStatus(item.publish)} darken-2`"
            @click="updateFields(item, { publish: !item.publish })">{{ getIconPublishingStatus(item.publish) }}
          </v-icon>
        </template>
        <span>{{ item.publish ? 'Despublicar' : 'Publicar' }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="managerFolders(item)">
            <v-icon small class="mr-2" color="amber">
              mdi-folder-multiple</v-icon>
          </v-btn>
        </template>
        <span>Gerenciar Pastas</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="editData(item, 'dialogDashboardsForm')">
            <v-icon small class="mr-2" color="dark">
              mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="removeData(item)">
            <v-icon small class="mr-2" color="red">
              mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>

    <template v-slot:no-data>
      <p class="red--text">Não há registros a serem exibidos!</p>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} de {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
  import DashboardsForm from './DashboardsForm';
  import Folders from './Folders';
  import DashboardService from '../../services/Dashboard';
  import HostService from '../../services/QlikHost';
  import AppService from '../../services/QlikApp';
  import GroupService from '../../services/Group';
  import CrudDataTableMixin from '../../mixins/CrudDataTableMixin';
  import Swal from 'sweetalert2';

  export default {
    name: 'dashboards',
    mixins: [CrudDataTableMixin],
    components: {
      DashboardsForm, Folders
    },
    created() {
      this.getHostsDataFromApi();
      this.getGroupsDataFromApi();
    },
    data() {
      return {
        service: DashboardService,
        dialogForm: false,
        nameCollection: 'collection',
        headers: [
            { text: '#', value: 'index', width: 50, sortable: false },
            { text: 'Dashboard', align: 'start', value: 'name' },
            { text: 'Grupo', align: 'start', value: 'group', sortable: false },
            { text: 'Ícone menu', value: 'icon', sortable: false },
            { text: 'Imagem de capa', value: 'image_url', sortable: false },
            { text: 'ID do App', value: 'app', sortable: false },
            { text: 'Host', value: 'host', sortable: false },
            { text: 'Ações', value: 'actions', sortable: false, width: 200 }
        ],
        filters: {
          search: '',
          host_id: '',
          app_id: '',
          page: '',
          sortBy: '',
          sortOrder: '',
          per_page: ''
        },
        groups: [],
        loadingGroups: false,
        hosts: [],
        loadingHosts: false,
        apps: [],
        loadingApps: false,
        loadingClearCache: false,
        dashboard: null,
        dialogFolders: false
      }
    },
    methods: {
        getGroupsDataFromApi() {
            this.loadingGroups = true;
            GroupService.getAll()
            .then(response => this.groups = response.data.data.map((r, idx) => this.getDataFromModelApi(r, idx)))
            .finally(() => this.loadingGroups = false);
        },
        getHostsDataFromApi() {
            this.loadingHosts = true;
            HostService.getAll()
            .then(response => {
                this.hosts = response.data.data.map((r, idx) => this.getDataFromModelApi(r, idx));
                if (this.hosts.length == 1) {
                this.filters.host_id = this.hosts[0].id;
                }
            })
            .finally(() => this.loadingHosts = false);
        },
        getAppsDataFromApi() {
            this.loadingApps = true;
            AppService.getAll(`?host_id=${this.filters.host_id}`)
            .then(response => this.apps = response.data.data.map((r, idx) => this.getDataFromModelApi(r, idx)))
            .finally(() => this.loadingApps = false);
        },
        managerFolders(item) {
          this.dashboard = item;
          this.dialogFolders = true;
        },
        closeManagerFolders() {
          this.dashboard = null;
          this.dialogFolders = false;
        },
        getIconPublishingStatus(publishingStatus) {
            return publishingStatus ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline';
        },
        getColorPublishingStatus(publishingStatus) {
            return publishingStatus ? 'green' : '';
        },
        clearCache(item = null) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Esta operação pode demorar alguns segundos para ser finalizada. Deseja continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.value) {
            this.loadingClearCache = true;
            let data = { id: null, refresh: false };

            if (item) {
              data.id = item.id;
            }

            DashboardService.dashboardClear(data)
              .then(() => {
                this.getDataFromApi();
                Swal.fire({ title: 'Sucesso!', icon: 'success', text: 'Operação de limpeza de cache finalizada.' });
              })
              .finally(() => this.loadingClearCache = false);
            }
          });
        }
    },
    watch: {
      'filters.host_id': function() {
        this.getAppsDataFromApi();
      }
    }
  }
</script>