<template>
    <v-card>
        <v-card-title>
            <span class="headline">Auditoria detalhada</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="audit">
                    <v-col cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Tipo de dado</th>
                                        <th class="text-left">ID</th>
                                        <th class="text-left">Ação</th>
                                        <th class="text-left">URL</th>
                                        <th class="text-left">IP</th>
                                        <th class="text-left">Usuário</th>
                                        <th class="text-left">Modificado em</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ auditableType(audit.auditable_type).label }}</td>
                                        <td>{{ audit.auditable_id }}</td>
                                        <td>{{ events[audit.event] || '' }}</td>
                                        <td>{{ audit.url }}</td>
                                        <td>{{ audit.ip_address }}</td>
                                        <td>#{{ audit.user_id }} {{ audit.relationships.user.name }}</td>
                                        <td>{{ audit.updated_at }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>

                    <v-col cols="12">
                        <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Informação alterada</th>
                                        <th class="text-left">Valor antigo</th>
                                        <th class="text-left">Valor novo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="change in changes" :key="change.name">
                                        <td>{{ change.field }}</td>
                                        <td>{{ change.old }}</td>
                                        <td>{{ change.new }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text class="mb-2" @click="$emit('close')">
                FECHAR
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'audit-details',
        props: {
            events: {
                type: Object, default: () => {}
            },
            auditable_types: {
                type: Array, default: () => []
            }
        },
        data() {
            return {
                audit: null,
                changes: []
            }
        },
        methods: {
            setAudit(audit) {
                this.audit = audit;
                this.setChanges();
            },
            setChanges() {
                if (this.audit) {
                    let oldIsBiggest = Object.keys(this.audit.old_values).length >= Object.keys(this.audit.new_values).length;
                    let keys = oldIsBiggest ? Object.keys(this.audit.old_values) : Object.keys(this.audit.new_values);

                    this.changes = keys.map(key => {
                        return {
                            field: key,
                            old: this.audit.old_values[key] || '<vazio>',
                            new: this.audit.new_values[key] || '<vazio>',
                            event: this.audit.event || '',
                            auditable_id: this.audit.auditable_id || '',
                            auditable_type: this.audit.auditable_type || '',
                        };
                    });
                } else {
                    this.changes = [];
                }
            },
            auditableType(auditable_type) {
                return this.auditable_types.find(type => type.model == auditable_type);
            }
        }
    }
</script>
