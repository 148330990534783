<template>
  <div class="iframe-container">
    <iframe title="Acidentes ABCR" width="100%" height="100%" :src="link" frameborder="0" allowFullScreen="true"   @load="scrollToTop"></iframe>
  </div>
</template>
  
<script>
export default {
  name: 'PowerBiIframe',
  data() {
    return {
      link: "https://app.powerbi.com/view?r=eyJrIjoiYWRhNjk5MjUtNzcxZC00ODZkLWIwNTktMTc5NmFkN2JkYjlhIiwidCI6Ijc1MjZjOTFlLTViNTktNDI0Yi1hOWNlLTQ0YWYzYzg2Zjk2MyJ9"
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
  
<style>
.iframe-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin-left: -20px;
}

.iframe-container iframe {
  height: 100%;
  width: 100%;
  margin-left: -10px;
}
</style>
  