<template>
  <v-data-table
    dense
    :headers="headers"
    :items="filters.dashboard_id ? getItemsFromCollection : []"
    :options.sync="options"
    :server-items-length="collection.meta.total || 0"
    class="elevation-1"
    :footer-props="configDataTable"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Pastas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-text-field @change="filters.search = $event" :value="filters.search"
          append-icon="mdi-magnify" label="Pesquisar" hide-details></v-text-field>

        <v-divider class="mx-4" inset vertical></v-divider>

        <v-btn color="warning" dark class="mb-2" @click.prevent="handleOrdering" :loading="loadingOrdering">
          {{ !enableOrdering ? 'Ordenar' : 'Salvar ordenação' }}
        </v-btn>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialogForm" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="addData('dialogFoldersForm')">
              Adicionar
            </v-btn>
          </template>

          <FoldersForm
            ref="dialogFoldersForm"
            :dashboard="dashboard"
            @dataFormSaved="dataSaved"
            @cancelSavingForm="closeDialogForm" />
        </v-dialog>
      </v-toolbar>

      <v-progress-linear indeterminate color="primary" v-show="loading"></v-progress-linear>
    </template>

    <template v-slot:body="props">
      <draggable v-bind="dragOptions" tag="tbody" :list="props.items" id="dragable-folders">
        <tr v-for="(item, key) in props.items" :key="item.id" :data-id="item.id">
          <td v-for="(header, idx) in headers" :key="idx" class="text-start">
            <span v-if="header.value == 'name'">
              <span>{{ item.name }}</span>
              <br /><small>{{ item.alias }}</small>
            </span>

            <span v-else-if="header.value == 'actions'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="editData(item, 'dialogFoldersForm')">
                    <v-icon small class="mr-2" color="dark">
                      mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="removeData(item)">
                    <v-icon small class="mr-2" color="red">
                      mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </span>

            <span v-else-if="header.value == 'order'">
              {{ item[header.value] }}
              <span v-if="(key+1) != item[header.value]" class="green--text">
                ({{ (key+1) }})
              </span>
            </span>

            <span v-else>{{ item[header.value] }}</span>
          </td>
        </tr>
      </draggable>
    </template>

    <template v-slot:no-data>
      <p class="red--text">Não há registros a serem exibidos!</p>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} de {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
  import FoldersForm from './FoldersForm';
  import FolderService from '../../services/Folder';
  import CrudDataTableMixin from '../../mixins/CrudDataTableMixin';
  import Draggable from 'vuedraggable';
  import Swal from 'sweetalert2';

  export default {
    name: 'folders',
    mixins: [CrudDataTableMixin],
    props: {
      dashboard: {
        type: Object, default: () => null
      }
    },
    components: {
      FoldersForm, 
      Draggable
    },
    data() {
      return {
        service: FolderService,
        dialogForm: false,
        nameCollection: 'collection',
        headers: [
          { text: '#', value: 'index', width: 50, sortable: false },
          { text: 'Pasta', value: 'name' },
          { text: 'Descrição', value: 'description' },
          { text: 'Ordem de apresentação', value: 'order' },
          { text: 'Ações', value: 'actions', sortable: false, width: 200 }
        ],
        filters: {
          search: '',
          dashboard_id: '',
          page: 1,
          sortBy: 'order'
        },
        enableOrdering: false,
        loadingOrdering: false
      }
    },
    methods: {
      handleOrdering() {
        if (this.enableOrdering) {
          this.saveOrdering();
        } else {
          this.enableOrdering = !this.enableOrdering;
        }
      },
      saveOrdering() {
        this.loadingOrdering = true;
        let folderItems = document.getElementById('dragable-folders');
        let folders = [];

        for(let idx in folderItems.children) {
          if (folderItems.children[idx] && typeof folderItems.children[idx] == 'object') {
            folders.push({
              id: folderItems.children[idx].getAttribute('data-id'),
              order: parseFloat(idx)+1
            });
          }
        }

        FolderService.updateOrders({folders})
          .then(() => {
            this.getDataFromApi();
            this.enableOrdering = false;

            Swal.fire({
              title: 'Sucesso!', icon: 'success', text: 'Nova ordenação salva com sucesso!',
              showConfirmButton: false, timer: 2000
            });
           }).finally(() => this.loadingOrdering = false);
      },
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          disabled: !this.enableOrdering,
          ghostClass: 'ghost'
        };
      }
    },
    watch: {
      dashboard: {
        handler() {
          if (this.dashboard) {
            this.filters.dashboard_id = this.dashboard.id;
          } else {
            this.filters.dashboard_id = null;
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style scoped>
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>