<template>
  <div>
    <v-expansion-panels class="my-2" focusable v-if="getGroups.length">
      <v-expansion-panel>
        <v-expansion-panel-header>Selecione um grupo para filtrar os paineis</v-expansion-panel-header>

        <v-expansion-panel-content class="pa-2">
          <v-chip v-for="(group, index) in getGroups" :key="index" class="ma-2" dark style="border-color: #004E85; color: #004E85;" label
            :outlined="!groupFilters.includes(group.name)" @click="toggleFilter(group.name)">
            {{ group.name }}
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row justify="start" class="pt-5" v-if="getDashboards.length">
      <v-col v-for="dashboard in getDashboards" :key="dashboard.id" cols="12" sm="6" md="4" lg="3" xl="2">
        <v-card min-height="250">
          <v-img height="96" :src="dashboard.image_url"></v-img>
          <v-card-title class="body-1"> {{ dashboard.name }} </v-card-title>
          <v-card-subtitle class="caption">
            {{ dashboard.relationships.group.name.toUpperCase() }}
          </v-card-subtitle>
          <v-card-text v-show="showDescription(`description-${dashboard.id}`)">
            {{ dashboard.description }}
          </v-card-text>
          <v-divider class="mx-4"></v-divider>

          <v-card-actions>
            <v-btn @click="trackClick(dashboard)" v-if="dashboard.id !== 35" text style="color: #004E85;" color="darken-4" >
              Visualizar</v-btn>
            <v-btn v-else text style="color: #004E85;" color="darken-4" @click="goToPowerBi('Painel de Acidentes - ABCR')">
              Visualizar</v-btn>
            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="dashboard.id !== 35"  icon v-clipboard="dashboardUrl(dashboard.id)" v-bind="attrs"
                  v-on="on"><v-icon>mdi-share-variant</v-icon>
                </v-btn>
              </template>
              <span>Copiar o link para o clipboard</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="toggleDescription(`description-${dashboard.id}`)">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Ver descrição do dashboard</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else align="center" justify="center">
      <h3 class="my-5">Não há dashboards a serem exibidos.</h3>
    </v-row>
  </div>
</template>

<script>
/* global gtag */
import GroupService from '../services/Group';

export default {
  name: 'Home',
  props: {
    dashboards: {
      type: Array, default: () => []
    },
    authorizedGroups: {
      type: Array, default: () => []
    }
  },
  created() {
    this.getDataGroupsFromApi();
  },
  data() {
    return {
      groupFilters: [],
      visible_descriptions: [],
      selectedGroup: '',
      dialog: false,
      groups: [],
    };
  },
  methods: {
    goToPowerBi(name) {
      gtag('event', 'click', {
        'event_category': 'Dashboard',
        'event_label': name
      });
      this.$router.push({ name: 'seguranca-viaria' });
    },
    toggleFilter(tagName) {
      var index = this.groupFilters.indexOf(tagName);
      if (index > -1) {
        this.groupFilters.splice(index, 1);
      } else {
        this.groupFilters.push(tagName);
      }
    },
    showDescription(el) {
      var index = this.visible_descriptions.indexOf(el);
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    },
    toggleDescription(el) {
      var index = this.visible_descriptions.indexOf(el);
      if (index >= 0) {
        this.visible_descriptions.splice(index, 1);
      } else {
        this.visible_descriptions.push(el);
      }
    },
    getDataGroupsFromApi() {
      this.loading = true;
      GroupService.getAll()
        .then(response => this.groups = response.data.data.map((resource, index) => this.getDataFromModelApi(resource, (index + 1))))
        .finally(() => this.loading = false);
    },
    dashboardUrl(dashboard_id) {
      let link = this.$router.resolve({ name: 'dashboard', params: { id: dashboard_id } });
      return window.location.origin + link.href;
    },
    trackClick(dashboard) {
      gtag('event', 'click', {
        'event_category': 'Dashboard',
        'event_label': dashboard.name
      });
      this.$router.push({ name: 'dashboard', params: { id: dashboard.id } });
    }
  },
  computed: {
    getDashboards() {
      if (this.groupFilters.length) {
        return this.dashboards.filter(dashboard => this.groupFilters.includes(dashboard.relationships.group.name));
      }

      return this.dashboards;
    },
    getGroups() {
      if (this.$store.getters['auth/checkAuth']) {
        if (!this.$store.getters['auth/isAdmin']) {
          return this.groups.filter(group => this.authorizedGroups.includes(group.id));
        }

        return this.groups;
      }
      return [];
    }
  }
};
</script>
