import Service from './Service';
import axios from 'axios';

class User extends Service {
    constructor() {
        super('users');
    }
    
    changePassword(userId, currentPassword, newPassword, confirmPassword) {
        return axios.post(`${this.base}/${userId}/change-password`, {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_new_password: confirmPassword
        }, {
            headers: this.getHeaders(),
        });
    }

    resetPasswordAndSendEmail(email) {
        return axios.post(`${this.base}/reset-password-email`, {
            email: email
        }, {
            headers: this.getHeaders(),
        });
    }
}

export default new User();
