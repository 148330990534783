import Service from './Service';

class Folder extends Service {
    constructor() {
        super('folders');
    }

    updateOrders(data) {
        return this.axios.post(`${this.base}/updateOrders`, data);
    }
}

export default new Folder();
