<template>
  <v-card flat class="pa-1">
    <v-card-actions class="pa-0" style="z-index: 2; position: absolute; width: 99%;">
      <v-spacer></v-spacer>
      <v-tooltip v-if="xlsButton" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="$refs.qlikRef.exportToExcel(); overlay = true;"
            v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
        </template>
        <span>Exportar para xlsx</span>
      </v-tooltip>

      <v-tooltip v-if="pdfButton" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="$refs.qlikRef.exportToPdf(); overlay = true;"
            v-bind="attrs" v-on="on">mdi-pdf-box</v-icon>
        </template>
        <span>Exportar para PDF</span>
      </v-tooltip>

      <v-tooltip v-if="csvButton" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="$refs.qlikRef.exportByCommaSeparator(); overlay = true;"
            v-bind="attrs" v-on="on">mdi-file-delimited-outline</v-icon>
        </template>
        <span>Exportar para CSV</span>
      </v-tooltip>
      
      <v-tooltip v-if="imageButton" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="$refs.qlikRef.exportToPng(); overlay = true;"
            v-bind="attrs" v-on="on">mdi-file-image-outline</v-icon>
        </template>
        <span>Exportar para imagem</span>
      </v-tooltip>

      <v-tooltip v-if="infoButton" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        <span>{{ infoDescription }}</span>
      </v-tooltip>

      <Maximizar 
        v-if="maximize"  
        :app="app"
        :type="type"
        :objectId="objectId"
        :height="400" />
    </v-card-actions>
  
    <v-card-text class="pa-0">
      <QlikInterface
        v-if="!forceUpdate"
        ref="qlikRef"
        :qAppPromise.sync="qAppPromise" :qDocPromise.sync="qDocPromise"
        :qdtViz.sync="qdtViz" :type="type" :props="config" :app="app" />

      <v-overlay :value="overlay">
        <div class="text-center">
          <v-progress-circular indeterminate size="64" />
          <div class="mt-5 mx-5 title">
            <p>Sua solicitação foi registrada.</p>
            <p>Certifique-se que seus pop-ups estão liberados</p>
          </div>
        </div>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>

<script>
import QlikInterface from './QlikInterface';
import Maximizar from './Maximizar';

export default {
  name: 'EmbedQlikObject',
  components: {
    QlikInterface,
    Maximizar
  },
  props: {
    app: {
      type: Object,
      required: true
    },
    objectId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: false
    },
    pdfButton: {
      type: Boolean
    },
    imageButton: {
      type: Boolean
    },
    xlsButton: {
      type: Boolean
    },
    csvButton: {
      type: Boolean
    },
    infoButton: {
      type: Boolean
    },
    infoDescription: {
      type: String
    },
    forceUpdate: {
      type: Boolean
    },
    maximize: {
      type: Boolean
    }
  },

  data: () => ({
    qAppPromise: null,
    qDocPromise: null,
    qdtViz: null,
    type: 'QdtViz',
    overlay: false
  }),

  watch: {
    forceUpdate(value) {
      if (value) {
        this.$nextTick(() => {
          this.$emit('update:forceUpdate', false);
        });
      }
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 4000);
    }
  },

  computed: {
    config() {
      return {
        id: this.objectId,
        height: this.height + 'px',
        exportImgOptions: { width: 600, height: 400, format: 'JPG' },
        exportPdfOptions: { documentSize: { width: 300, height: 150 } }
      };
    }
  }  
};
</script>

<style scoped>
.v-card-actions,
.v-card-text {
  padding: 0 !important;
}
</style>
