<template>
  <v-app>
    <v-app-bar v-if="!$route.params.embed" app color="primary" dark>
      <div class="d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" @click="drawer = !drawer" class="mr-5">mdi-forwardburger</v-icon>
          </template>
          <span>Open menu</span>
        </v-tooltip>

        <a href="https://www.infrasa.gov.br" target="_blank">
          <v-img alt="Logo" class="shrink mr-5" contain src="./assets/logo-transparente.png" width="120" transition="scale-transition" />
        </a>
      </div>
      <div>
        <span class="caption font-weight-light d-sm-none d-md-flex d-none d-sm-flex">
          Plataforma de Painéis Analíticos do
        </span>
        <span class="title font-weight-light d-sm-none d-md-flex d-none d-sm-flex">
          Observatório Nacional de Transporte e Logística
        </span>
      </div>

      <v-spacer></v-spacer>

      <v-btn class="mr-2 d-sm-none d-md-flex d-none d-sm-flex" href="https://ontl.infrasa.gov.br/" target="_blank" text>
        <span>Visite o Portal ONTL</span>
        <v-icon small>mdi-open-in-new</v-icon>
      </v-btn>

      <v-img alt="Logo ONTL" class="shrink mx-5" contain src="./assets/ontl_branca.png" width="100" transition="scale-transition" />

      <NavigationDrawer :drawer.sync="drawer" :showLogin.sync="showLogin" :dashboards="getDashboards" :authorizedGroups="authorizedGroups" />
    </v-app-bar>

    <v-main class="mx-5">
      <LoginForm :showLogin.sync="showLogin" />
      <router-view :dashboards="getDashboards" :authorizedGroups="authorizedGroups"></router-view>

      <v-overlay v-if="this.$store.getters.loadingClearingCache">
        <v-row align="center" justify="center">
          <v-progress-circular class="mb-5" indeterminate size="64"></v-progress-circular>
        </v-row>

        <v-row align="center" justify="center">
          <div class="title">
            Carregando, Aguarde...
          </div>
        </v-row>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer';
import LoginForm from '@/components/LoginForm';

import DashboardService from './services/Dashboard';

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    LoginForm
  },
  data: () => ({ 
    drawer: false,
    removeu: false,
    showLogin: false,
    dashboards: []
  }),
  created() {
    this.getDataDashboardsFromApi();
  },
  methods: {
    getDataDashboardsFromApi() {
      this.loading = true;
      DashboardService.getAll(`?sortBy=name${!this.$store.getters['auth/checkAuth'] ? '&publish=1' : ''}`)
        .then(response => this.dashboards = response.data.data.map((resource, index) => this.getDataFromModelApi(resource, (index+1))))
        .finally(() => this.loading = false);
    }
  },
  computed: {
    authorizedGroups() {
      return this.$store.getters['auth/authorizedGroups'].map(group => group.id);
    },
    getDashboards() {
      if (this.$store.getters['auth/checkAuth'] && !this.$store.getters['auth/isAdmin']) {
        return this.dashboards.filter(dashboard => this.authorizedGroups.includes(dashboard.group_id));
      }

      return this.dashboards
    }
  },
  watch: {
    '$route.path': function(path) {
      if (['/', '/home'].includes(path)) {
        this.getDataDashboardsFromApi();
      }
    }
  }
};
</script>
