export default {
  props: {
    id: {
      type: Number, default: null
    }
  },
  created() {
    if (this.id) {
      this.setResourceId(this.id);
      this.getDataFromApi();
    }
  },
	data() {
		return {
      resource_id: null,
      formTitle: '',
      formValid: false
		}
  },
  methods: {
    setResourceId(id) {
      this.resource_id = id;
    },
    getDataFromApi() {
      this.loading.save = true;
      this.service.get(this.resource_id)
        .then(response => this.resource = this.getDataFromModelApi(response.data))
        .finally(() => this.loading.save = false);
    },
    save() {
      if (!this.$refs.formValid.validate()) {
        return;
      }

      this.loading.save = true;
      let request;
      if (this.resource_id) {
        request = this.service.update(this.resource_id, this.resource);
      } else {
        request = this.service.save(this.resource);
      }

      request.then(() => {
        this.$emit('dataFormSaved');
        this.resetData();
      }).finally(() => this.loading.save = false);
    },
    cancelSaving() {
      this.$emit('cancelSavingForm');
      this.resetData();
    },
    resetData() {
      this.resource_id = null;
      this.resource = { ...this.model };
    }
  },
  watch: {
    resource_id(id) {
      if (!id) {
        this.resetData();
        this.formTitle = this.labels.formTitleAdd;
      } else {
        this.formTitle = this.labels.formTitleEdit;
      }
    }
  }
}